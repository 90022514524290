import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Theme,
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ListIncomingRoutes } from '../components/ListIncomingRoutes';
import {
  DocumentFile,
  RouteType,
  useGetInternalTerminalFromIdQuery,
  useTruckFillAndTimeUpdateSubscription,
  useGetProductionGroupLazyQuery,
} from '../generated/graphql';
import { getTimeAsNumberOfMinutes } from '../lib/date';
import { useHttpClient } from '../providers/HttpClientProvider';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { Maybe } from 'graphql/jsutils/Maybe';
import { RefreshOutlined } from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import PreviousIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import { timeToMinute } from '../lib/time';

const useStyles = makeStyles((theme: Theme) => ({
  selectDateRoot: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',
    '& form > *': {
      margin: theme.spacing(0, 1),
    },
    alignItems: 'center',
  },
  filterButtonRoot: {
    marginLeft: 2,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  checkBoxBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #dedede',
    paddingRight: 0,
    paddingLeft: 10,
    marginRight: 4,
  },
  checkBox: {
    padding: 3,
  },
  kpiBox: {
    border: '1px solid #dedede',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  rootGrid: {
    marginTop: 2,
  },
  btnSelected: {
    border: '1px solid #68BC46',
    backgroundColor: '#AAE590',
  },
  inputValTime: {
    height: 15,
    width: 120,
    fontSize: 15,
  },
}));

export interface LegsResponse {
  id: number;
  arrivalTime: string;
  departureTime?: Maybe<string>;
  actualArrivalTime?: Maybe<string>;
  actualArrivalDate?: Maybe<string>;
  actualDepartureTime?: Maybe<string>;
  actualDepartureDate?: Maybe<string>;
  productionDate: string;
  transportationDate: string;
  actualCapacity?: Maybe<number>;
  loadCar?: Maybe<number>;
  loadCarPPL?: Maybe<number>;
  loadCages?: Maybe<number>;
  loadTrailer?: Maybe<number>;
  loadTrailerPPL?: Maybe<number>;
  unloadCar?: Maybe<number>;
  unloadCarPPL?: Maybe<number>;
  unloadCages?: Maybe<number>;
  unloadTrailer?: Maybe<number>;
  unloadTrailerPPL?: Maybe<number>;
  arrivingPallets?: Maybe<number>;
  arrivingPalletSpace?: Maybe<number>;
  timeObj?: {
    originalArrTime: string;
    actualArrTime: string;
    originalDepartTime: string;
    actualDepartTime: string;
    terminalName: string;
    legId: number;
    transportationDate: string;
    arrivingPallets: number;
    arrivingPalletSpace: number;
  }[];
  note: string;
  unload: boolean;
  load: boolean;
  gateNumber?: Maybe<string> | undefined;
  carRegistrationNumber?: Maybe<string> | undefined;
  trailerRegistrationNumber?: Maybe<string> | undefined;
  terminalNickname?: Maybe<string> | undefined;
  terminalNote: string;
  lastUpdatedBy?: Maybe<string>;
  updatedAt: string;
  isNonArrivalMarked?: boolean;
  nonArrivalMarkedFrom?: Maybe<string>;
  isSealBroken?: Maybe<boolean>;
  sealNumber?: Maybe<string> | undefined;
  productionGroup?: Maybe<string> | undefined;
  arrivalStatus?: Maybe<string> | undefined;
  turnummer?: Maybe<number> | undefined;
  togref?: Maybe<string> | undefined;
  bpx?: Maybe<number> | undefined;
  pri03?: Maybe<number> | undefined;
  pri49?: Maybe<number> | undefined;
  upri03?: Maybe<number> | undefined;
  upri49?: Maybe<number> | undefined;
  parti?: Maybe<number> | undefined;
  brev?: Maybe<number> | undefined;
  pru?: Maybe<number> | undefined;
  tom?: Maybe<boolean> | undefined;
  files: Pick<
    DocumentFile,
    'id' | 'size' | 'path' | 'mimetype' | 'originalname'
  >[];
  loadingListItems: {
    pallets?: Maybe<number>;
    palletSpace?: Maybe<number>;
    isDangerous: boolean;
  }[];
  location?: Maybe<{
    id: number;
    city: string;
    country: string;
  }>;
  route: {
    id: number;
    isCreatedFromTerminal: boolean;
    isCreatedFromPlanned: boolean;
    type: RouteType;
    routeId: string;
    capacity?: Maybe<number>;
    routeCreatedBy: string;
    routeLastUpdatedBy: string;
    routeUpdatedAt: Date;
    routeDeletedAt: Date;
    routeDeletedBy: string;
    trailerCarrierId: string;
    carCarrierId: string;
    tourRoute?: Maybe<{
      tourId: number;
    }>;
    transportationDate: string;
    driverName: string;
    subcontractor: {
      id: number;
      name: string;
    };
    legs: {
      location?: Maybe<{
        id: number;
        name: string;
        city: string;
        country: string;
      }>;
    }[];
  };
}

interface TruckFillAndTimeProps {
  terminal: {
    id: number;
    name: string;
  };
}

export interface FilterConfiguration {
  inboundDomestic?: boolean;
  outboundDomestic?: boolean;
  inboundImport?: boolean;
  outboundExport?: boolean;
}

export function TruckFillAndTime(props: TruckFillAndTimeProps) {
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { terminal } = props;
  const NEW_DATE_FORMAT = 'yyyy-MM-dd HH:mm';
  const [gridTop, setGridTop] = useState(0);
  const [productionGroup, setProductionGroup] = useState('');

  const [productionGroupArray, setProductionGroupArray] = useState<string[]>(
    [],
  );

  const filterGroupText = ['In.Domestic', 'Out.Domestic', 'Export', 'Import'];
  const classes = useStyles();
  const {
    getCustomConfiguration: getOtherConfiguration,
    updateCustomConfiguration: updateOtherConfiguration,
  } = useUserConfiguration();

  const filterConfiguration = (getOtherConfiguration(
    'truckFillAndTime',
    'filters',
  ) ?? {
    inboundDomestic: false,
    outboundDomestic: false,
    inboundImport: false,
    outboundExport: false,
  }) as FilterConfiguration;

  const [legsData, setLegsData] = useState<LegsResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [selectedFilterGroup, setSelectedFilterGroup] = useState(
    filterConfiguration.inboundDomestic === true
      ? 'In.Domestic'
      : filterConfiguration.outboundDomestic === true
      ? 'Out.Domestic'
      : filterConfiguration.inboundImport === true
      ? 'Import'
      : filterConfiguration.outboundExport === true
      ? 'Export'
      : 'In.Domestic',
  );

  useTruckFillAndTimeUpdateSubscription({
    variables: {
      terminalId: terminal.id || 0,
    },
    onSubscriptionData({ subscriptionData }) {
      if (
        subscriptionData.data?.truckFillAndTimeUpdate.refreshWholeGrid === false
      ) {
        if (legsData) {
          const index = legsData.findIndex(
            (l) =>
              l.id ===
              subscriptionData.data?.truckFillAndTimeUpdate.routeLeg.id,
          );
          if (index !== -1) {
            const subUpdatedLeg =
              subscriptionData.data?.truckFillAndTimeUpdate.routeLeg;
            const legResp = legsData[index];
            legResp.actualArrivalTime = subUpdatedLeg.actualArrivalTime;
            legResp.actualArrivalDate = subUpdatedLeg.actualArrivalDate;
            legResp.actualDepartureTime = subUpdatedLeg.actualDepartureTime;
            legResp.actualDepartureDate = subUpdatedLeg.actualDepartureDate;
            legResp.loadCar = subUpdatedLeg.loadCar;
            legResp.loadCarPPL = subUpdatedLeg.loadCarPPL;
            legResp.loadTrailer = subUpdatedLeg.loadTrailer;
            legResp.loadTrailerPPL = subUpdatedLeg.loadTrailerPPL;
            legResp.loadCages = subUpdatedLeg.loadCages;
            legResp.actualCapacity = subUpdatedLeg.actualCapacity;
            legResp.gateNumber = subUpdatedLeg.gateNumber;
            legResp.carRegistrationNumber = subUpdatedLeg.carRegistrationNumber;
            legResp.trailerRegistrationNumber =
              subUpdatedLeg.trailerRegistrationNumber;
            legResp.unloadCar = subUpdatedLeg.unloadCar;
            legResp.unloadCarPPL = subUpdatedLeg.unloadCarPPL;
            legResp.unloadTrailer = subUpdatedLeg.unloadTrailer;
            legResp.unloadTrailerPPL = subUpdatedLeg.unloadTrailerPPL;
            legResp.unloadCages = subUpdatedLeg.unloadCages;
            legResp.isNonArrivalMarked = subUpdatedLeg.isNonArrivalMarked;
            legResp.isSealBroken = subUpdatedLeg.isSealBroken;
            legResp.sealNumber = subUpdatedLeg.sealNumber;
            legResp.terminalNickname = subUpdatedLeg.terminalNickname;
            legResp.terminalNote = subUpdatedLeg.terminalNote;
            legResp.productionGroup = subUpdatedLeg.productionGroup;
            legResp.arrivalStatus = subUpdatedLeg.arrivalStatus;
            legResp.note = subUpdatedLeg.note;
            legResp.files = subUpdatedLeg.files;
            legResp.updatedAt = subUpdatedLeg.updatedAt;
            if (subUpdatedLeg.lastUpdatedBy)
              legResp.lastUpdatedBy = subUpdatedLeg.lastUpdatedBy;
            legsData.splice(index, 1, legResp);
            setLegsData([...legsData]);
          }
        }
      }
    },
  });

  const { data: terminalData } = useGetInternalTerminalFromIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      internalTerminalId: terminal.id as number,
    },
  });

  const onExportTFTClicked = () => {
    if (legsData && legsData.length > 0) {
      setExportExcelLoading(true);
      httpClient
        .downloadTftExcelBlob({
          terminalId: [terminal.id],
          startDateTime: tftStartDate,
          endDateTime: tftEndDate,
        })
        .then((res) => {
          const blob = res.data;
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.setAttribute(
            'download',
            `TFT-(${tftStartDate}_to_${tftEndDate})`,
          );
          setExportExcelLoading(false);
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    }
  };

  const getAllData = () => {
    setLoading(true);
    refetchLatestProductionGroup({
      variables: {
        terminalId: terminal.id as number,
        startDate: tftStartDate,
        endDate: tftEndDate,
      },
    });
    httpClient
      .getIncomingRouteLegs({
        terminalId: [terminal.id],
        startDateTime: tftStartDate,
        endDateTime: tftEndDate,
        routeFilterType: selectedFilterGroup,
        palletSum: true,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setLegsData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setLegsData([]);
          console.error('# tft API error=', res.data.message);
        }

        setLoading(false);
      })
      .catch((e) => {
        console.error('# tft UI error=', e);
        setLoading(false);
      });
  };

  const redirectToCreateRoute = (isLoading: boolean) => {
    const url = path.substring(0, path.lastIndexOf('/'));
    history.push({
      pathname: `${url}/create/sporadic-route`,
      state: {
        isLoading: isLoading,
        locationIdOfThisTerminal: terminalLocation?.id ?? 0,
        timestamp: new Date().getTime(),
      },
    });
  };

  const terminalLocation = terminalData?.internalTerminal.terminal.locations[0];
  const [isHideRegistered, setisHideRegistered] = useState<boolean>(false);

  //for start-end range
  const { getTFTTime, getTFTSameDay, setMasterDate } = useUserConfiguration();
  const isSameDaySelected = getTFTSameDay();
  const [isSameDayProd, setSameDayProd] = useState<boolean>(isSameDaySelected);
  const currTimeHour = new Date().getHours();
  const currTimeMinute = new Date().getMinutes();
  const isSameProdDayFound =
    !isSameDayProd &&
    timeToMinute(`${currTimeHour}:${currTimeMinute}`) <
      timeToMinute(getTFTTime(false));

  const myDate = isSameProdDayFound
    ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    : new Date();
  const startHour = parseInt(getTFTTime(true).split(':')[0]);
  const startMinute = parseInt(getTFTTime(true).split(':')[1]);
  const [startDateTime, setStartDateTime] = useState(
    myDate.setHours(startHour, startMinute),
  );
  const handleStartDateChanged = useCallback((value) => {
    if (value && value != 'Invalid Date') {
      setStartDateTime(value);
      //Format= Mon Jun 19 2023 10:00:00 GMT+0530
      setTftStartDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setTftStartDate('');
    }
  }, []);

  const endHour = parseInt(getTFTTime(false).split(':')[0]);
  const endMinute = parseInt(getTFTTime(false).split(':')[1]);
  const [endDateTime, setEndDateTime] = useState(
    isSameDaySelected
      ? new Date(myDate.getTime()).setHours(endHour, endMinute)
      : new Date(myDate.getTime() + 24 * 60 * 60 * 1000).setHours(
          endHour,
          endMinute,
        ),
  );
  const handleEndDateChanged = useCallback((value) => {
    if (value && value != 'Invalid Date') {
      setEndDateTime(value);
      setTftEndDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setTftEndDate('');
    }
  }, []);

  const [isBothDateValid, setBothDateValid] = useState<boolean>(false);
  const [tftStartDate, setTftStartDate] = useState<string>(
    format(startDateTime, NEW_DATE_FORMAT),
  );
  const [tftEndDate, setTftEndDate] = useState<string>(
    format(endDateTime, NEW_DATE_FORMAT),
  );

  const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSameDayProd(true);
      if (tftStartDate !== '' && tftEndDate !== '') {
        setMasterDate(
          `${format(new Date().setHours(0, 0), NEW_DATE_FORMAT)}#${format(
            new Date(new Date().getTime()).setHours(23, 59),
            NEW_DATE_FORMAT,
          )}`,
          true,
          true,
        );

        setTftEndDate(
          format(
            new Date(new Date().getTime()).setHours(23, 59),
            NEW_DATE_FORMAT,
          ),
        );
        window.location.reload();
      }
    } else {
      setSameDayProd(false);
      if (tftStartDate !== '' && tftEndDate !== '') {
        setMasterDate(
          `${format(
            new Date().setHours(startHour, startMinute),
            NEW_DATE_FORMAT,
          )}#${format(
            new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(
              23,
              59,
            ),
            NEW_DATE_FORMAT,
          )}`,
          true,
          false,
        );

        setTftEndDate(
          format(
            new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(
              23,
              59,
            ),
            NEW_DATE_FORMAT,
          ),
        );
        window.location.reload();
      }
    }
  };

  const isValidTime = (startTime: string, endTime: string): boolean => {
    const startMins = getTimeAsNumberOfMinutes(startTime);
    const endMins = getTimeAsNumberOfMinutes(endTime);
    return startMins < endMins;
  };

  const changeDatesAction = (isNext: boolean) => {
    const newDateStart = isNext
      ? new Date(new Date(tftStartDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(tftStartDate).getTime() - 1000 * 60 * 60 * 24);
    const newDateEnd = isNext
      ? new Date(new Date(tftEndDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(tftEndDate).getTime() - 1000 * 60 * 60 * 24);

    setStartDateTime(newDateStart.getTime());
    setEndDateTime(newDateEnd.getTime());
    const newTFTStartDateTime = format(newDateStart, NEW_DATE_FORMAT);
    const newTFTEndDateTime = format(newDateEnd, NEW_DATE_FORMAT);
    setTftStartDate(newTFTStartDateTime);
    setTftEndDate(newTFTEndDateTime);

    setMasterDate(`${newTFTStartDateTime}#${newTFTEndDateTime}`, true);
    setLoading(true);
    refetchLatestProductionGroup({
      variables: {
        terminalId: terminal.id as number,
        startDate: newTFTStartDateTime,
        endDate: newTFTEndDateTime,
      },
    });
    httpClient
      .getIncomingRouteLegs({
        terminalId: [terminal.id],
        startDateTime: newTFTStartDateTime,
        endDateTime: newTFTEndDateTime,
        routeFilterType: selectedFilterGroup,
        palletSum: true,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setLegsData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setLegsData([]);
          console.error('# tft API error=', res.data.message);
        }

        setLoading(false);
      })
      .catch((e) => {
        console.error('# tft UI error=', e);
        setLoading(false);
      });
  };

  const [refetchLatestProductionGroup, { data: productionGroupsData }] =
    useGetProductionGroupLazyQuery({
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (productionGroupsData) {
      setProductionGroupArray(productionGroupsData.getProductionGroup);
    }
  }, [productionGroupsData, legsData]);

  useEffect(() => {
    if (tftStartDate !== '' && tftEndDate !== '') {
      if (tftStartDate.split(' ')[0] === tftEndDate.split(' ')[0]) {
        setBothDateValid(
          isValidTime(tftStartDate.split(' ')[1], tftEndDate.split(' ')[1]),
        );
      } else {
        setBothDateValid(true);
      }
    } else {
      setBothDateValid(false);
    }
  }, [tftStartDate, tftEndDate]);

  useEffect(() => {
    if (tftStartDate !== '' && tftEndDate !== '') {
      getAllData();
    }
  }, [terminal.id, selectedFilterGroup]);

  useEffect(() => {
    if (tftStartDate !== '' && tftEndDate !== '') {
      getAllData();
      setMasterDate(`${tftStartDate}#${tftEndDate}`, false);
    }

    const element = document.getElementById('truckFillGrid');
    const positions = element?.getBoundingClientRect();

    if (positions) {
      setGridTop(positions.top);
    }
  }, []);

  return (
    <Grid container spacing={1} className={classes.rootGrid}>
      <Grid item xs={12}>
        <Paper className={classes.selectDateRoot}>
          <Box>
            <Typography variant="body1">{terminal.name}</Typography>
          </Box>

          <Box className={classes.filterButtonRoot}>
            <Box className={classes.checkBoxBox}>
              <FormControlLabel
                label={
                  <Typography style={{ fontSize: 12 }}>
                    {t('attributes.sameProdDay')}
                  </Typography>
                }
                control={
                  <Checkbox
                    name={'same_day'}
                    size="small"
                    checked={isSameDayProd}
                    onChange={handleCheckedChange}
                    className={classes.checkBox}
                  />
                }
              />
              <Tooltip title={`${t('attributes.sameDayBoxInfo')}`}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <Tooltip title={`Previous Date`}>
              <IconButton
                style={{ backgroundColor: '#e2e2e2', height: 38, width: 38 }}
                onClick={() => {
                  changeDatesAction(false);
                }}
              >
                <PreviousIcon />
              </IconButton>
            </Tooltip>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              allowKeyboardControl={true}
              label="Master Prod. Start"
              value={startDateTime}
              onChange={handleStartDateChanged}
              format="yyyy-MM-dd HH:mm"
              id="startDT"
              InputProps={{
                style: {
                  fontSize: 15,
                },
                classes: { input: classes.inputValTime },
                maxRows: 1,
              }}
              invalidDateMessage={'Invalid DateTime'}
              maxDate={endDateTime}
              maxDateMessage={'Invalid Range'}
              onError={(e) => {
                if (e) setBothDateValid(false);
              }}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: 'none' },
              }}
            />
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              allowKeyboardControl={true}
              label="Production End"
              value={endDateTime}
              onChange={handleEndDateChanged}
              format="yyyy-MM-dd HH:mm"
              id="endDT"
              InputProps={{
                style: {
                  fontSize: 15,
                },
                classes: { input: classes.inputValTime },
                maxRows: 1,
              }}
              minDate={startDateTime}
              minDateMessage={'Invalid Range'}
              invalidDateMessage={'Invalid DateTime'}
              onError={(e) => {
                if (e) setBothDateValid(false);
              }}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: 'none' },
              }}
            />
            <Tooltip title={`Next Date`}>
              <IconButton
                style={{ backgroundColor: '#e2e2e2', height: 38, width: 38 }}
                onClick={() => {
                  changeDatesAction(true);
                }}
              >
                <NextIcon />
              </IconButton>
            </Tooltip>
            <Button
              style={{ marginRight: 8, marginLeft: 4 }}
              variant="contained"
              color="primary"
              disabled={!isBothDateValid}
              onClick={() => {
                if (tftStartDate !== '' && tftEndDate !== '') {
                  setMasterDate(`${tftStartDate}#${tftEndDate}`, true);
                  getAllData();
                }
              }}
            >
              {`${t('attributes.view')}`}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={exportExcelLoading || legsData.length === 0}
              onClick={onExportTFTClicked}
            >
              {exportExcelLoading
                ? `${t('validation.loadingApi')}`
                : `${t('attributes.getExcel')}`}
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid container xs={12}>
        <Grid item container xs={10} style={{ justifyContent: 'flex-start' }}>
          <IconButton
            style={{ padding: 0, marginRight: 5 }}
            onClick={() => {
              if (tftStartDate !== '' && tftEndDate !== '') getAllData();
            }}
          >
            <RefreshOutlined />
          </IconButton>

          <Button
            style={{ padding: 3, marginRight: 3 }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => redirectToCreateRoute(true)}
          >
            {`${t('attributes.addTruckLoading')}`}
          </Button>
          <Button
            style={{ padding: 3, marginRight: 3 }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => redirectToCreateRoute(false)}
          >
            {`${t('attributes.addTruckUnloading')}`}
          </Button>
          <Divider orientation="vertical" />

          <TextField
            id="filter-group"
            style={{ width: 150, marginLeft: 5, padding: 0 }}
            select
            size="small"
            variant="standard"
            value={selectedFilterGroup}
            label={t(`attributes.filterGroup`)}
            onChange={(event) => {
              setSelectedFilterGroup(event.target.value as string);

              updateOtherConfiguration('truckFillAndTime', 'filters', {
                inboundDomestic:
                  (event.target.value as string) == filterGroupText[0],
                outboundDomestic:
                  (event.target.value as string) == filterGroupText[1],
                outboundExport:
                  (event.target.value as string) == filterGroupText[2],
                inboundImport:
                  (event.target.value as string) == filterGroupText[3],
              });
            }}
          >
            {filterGroupText.map((filterGroup) => (
              <MenuItem value={filterGroup}>{filterGroup}</MenuItem>
            ))}
          </TextField>
          {productionGroupArray.length > 0 ? (
            <TextField
              id="production-group"
              style={{ width: 150, marginLeft: 5, padding: 0 }}
              select
              size="small"
              variant="standard"
              value={productionGroup}
              label={t(`attributes.productionGroup`)}
              onChange={(event) => {
                setProductionGroup(event.target.value as string);
              }}
            >
              <MenuItem value="All-Groups">
                {t(`attributes.allGroups`)}
              </MenuItem>
              {productionGroupArray.map((productionGroup) => (
                <MenuItem value={productionGroup}>{productionGroup}</MenuItem>
              ))}
            </TextField>
          ) : null}
        </Grid>

        <Grid
          item
          container
          xs={2}
          direction={'row'}
          style={{ justifyContent: 'flex-end' }}
        >
          {legsData.length === 0 ? null : (
            <Button
              variant={isHideRegistered ? 'contained' : 'outlined'}
              style={{ marginRight: 5, padding: 5 }}
              color="primary"
              onClick={() => {
                setisHideRegistered(!isHideRegistered);
              }}
            >
              {!isHideRegistered
                ? t('attributes.hideRegistered')
                : t('attributes.showRegistered')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} id="truckFillGrid">
        {loading ? (
          <Box display="flex" justifyContent="center" mt="30vh">
            <CircularProgress />
          </Box>
        ) : (
          <ListIncomingRoutes
            loading={loading}
            legs={legsData}
            terminal={terminal}
            terminalLocation={terminalLocation}
            gridTop={gridTop}
            isHideRegistered={isHideRegistered}
            group={productionGroup}
            onGetTFTExcelClicked={() => onExportTFTClicked()}
          />
        )}
      </Grid>
    </Grid>
  );
}
