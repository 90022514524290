import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { LegFieldInput } from '../components/form/LegFields';
import { HelmetComponent } from '../components/HelmetComponent';
import { LoadingListItemInterface } from '../components/LoadingList';
import {
  TerminalRouteForm,
  SporadicRouteFormInput,
} from '../components/TerminalRouteForm';
import {
  FuelType,
  LoadingListItemInput,
  SporadicRouteInput,
  useCreateSporadicRouteMutation,
  useGetLocationLazyQuery,
} from '../generated/graphql';
import { getCurrentDate, getTimeOfToday } from '../lib/date';
import { DATE_FORMAT, timeOrNull } from '../lib/date_time';
import { numberOrNull, _toNumberOrUndefined } from '../lib/formHelpers/number';
import { routeValidationSchema } from '../lib/validationSchema/route';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { getCurrencyFromDepartment } from '../utils/GetCurrencyFromDepartment';

export function TerminalCreateSporadicRoute() {
  const router = useHistory();
  const { t } = useTranslation();
  const TIME_FORMAT = 'HH:mm';
  const { department } = useUserConfiguration();
  const [routeIsLoading, setRouteIsLoading] = useState<boolean>(false);
  const [routeIdName, setRouteIdName] = useState<string>('');
  const [allValToSave, setAllValToSave] = useState<SporadicRouteFormInput>();

  const [getLocationDetails, { data: locationData, loading: locationLoading }] =
    useGetLocationLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  const location = useLocation<{
    isLoading: boolean;
    locationIdOfThisTerminal: number;
    timestamp: number;
  }>();

  useEffect(() => {
    if (location.state && location.state.isLoading) {
      setRouteIsLoading(location.state.isLoading);
    }
  }, [location.state?.isLoading, location.state?.timestamp]);

  useEffect(() => {
    if (!locationLoading && locationData) {
      if (routeIsLoading) {
        setRouteIdName(`-Extra-Outbound-to-${locationData.location.city}`);
      } else {
        setRouteIdName(`-Extra-Inbound-from-${locationData.location.city}`);
      }
    }
  }, [locationData, locationLoading]);

  useEffect(() => {
    if (routeIdName !== '' && allValToSave) {
      finalSaveCall(allValToSave);
    }
  }, [routeIdName, allValToSave]);
  const [createSporadicRoute] = useCreateSporadicRouteMutation({});

  const onSubmit = async (
    val: Omit<SporadicRouteFormInput, 'dispatcherId' | 'subcontractorId'> & {
      dispatcherId?: number;
      subcontractorId?: number;
    },
  ) => {
    const values = val as SporadicRouteFormInput;
    if (routeIsLoading) {
      if (
        values.legs &&
        values.legs.length === 2 &&
        values.legs[1].locationId
      ) {
        getLocationDetails({
          variables: {
            id: values.legs[1].locationId,
          },
        });
      }
    } else {
      if (
        values.legs &&
        values.legs.length === 2 &&
        values.legs[0].locationId
      ) {
        getLocationDetails({
          variables: {
            id: values.legs[0].locationId,
          },
        });
      }
    }
    setAllValToSave(values);
  };

  const getLoadCarTrailerVal = (
    forCar: boolean,
    capacity: number,
    loadingList: LoadingListItemInterface[],
  ): number | undefined => {
    //sum
    if (loadingList && loadingList.length > 0) {
      const sumOfPalletSpace = _.sumBy(loadingList, 'palletSpace') ?? 0;
      if (capacity && capacity > 0 && sumOfPalletSpace > 0) {
        if (forCar && capacity < 19) {
          return sumOfPalletSpace;
        } else if (!forCar && capacity > 18) {
          return sumOfPalletSpace;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const finalSaveCall = async (values: SporadicRouteFormInput) => {
    const input: SporadicRouteInput = {
      isCreatedFromPlanned: false,
      isCreatedFromTerminal: true,
      transportationDate: values.transportationDate,
      agreedPrice: undefined,
      currency: values.currency,
      note: values.note,
      externalNote: values.externalNote,
      driverName: values.driverName,
      driverPhoneNumber: values.driverPhoneNumber,
      licensePlate: values.licensePlate,
      capacity: numberOrNull(values.capacity),
      kilometer: _toNumberOrUndefined(values.kilometer),
      weight: _toNumberOrUndefined(values.weight),
      routeId: `${values.routeId}${routeIdName}`,
      dispatcherId: values?.dispatcherId as number,
      subcontractorId: values?.subcontractorId as number,
      ftl: values.ftl,
      files: values.files.map((file) => ({
        id: file.id,
      })),
      legs: values.legs.map((leg, index) => ({
        load: leg.load,
        unload: leg.unload,
        fuel: leg.fuel,
        gateNumber: leg.gateNumber ?? '',
        carRegistrationNumber: leg.carRegistrationNumber ?? '',
        trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
        routeDriverName: leg.routeDriverName ?? '',
        routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
        actualDepartureDate: leg.transportationDate,
        actualArrivalDate: leg.transportationDate,
        actualDepartureTime:
          leg.load && leg.departureTime ? leg.departureTime : undefined,
        loadCar: leg.load
          ? getLoadCarTrailerVal(
              true,
              values.capacity ?? 0,
              leg.loadingListItems,
            )
          : undefined,
        loadTrailer: leg.load
          ? getLoadCarTrailerVal(
              false,
              values.capacity ?? 0,
              leg.loadingListItems,
            )
          : undefined,
        note: leg.note ?? '',
        arrivalTime: leg.arrivalTime ?? '00:00',
        departureTime: timeOrNull(leg.departureTime),
        position: index,
        productionDate: leg.productionDate ?? values.transportationDate,
        transportationDate: leg.transportationDate ?? values.transportationDate,
        transportationDateOffset: leg.transportationDateOffset,
        locationId: leg.locationId as number,
        location: undefined,
        loadingListItems: leg.load
          ? leg.loadingListItems.map(
              (loadingListItem): LoadingListItemInput => ({
                locationId: loadingListItem.locationId as number,
                packages: numberOrNull(loadingListItem.packages),
                pallets: numberOrNull(loadingListItem.pallets),
                palletSpace: numberOrNull(loadingListItem.palletSpace),
                weight: numberOrNull(loadingListItem.weight),
                note: loadingListItem.note,
                checked: loadingListItem.checked,
                isDangerous: loadingListItem.isDangerous,
                classification: loadingListItem.classification,
                unNumber: loadingListItem.unNumber,
                isLimitedQty: loadingListItem.isLimitedQty,
              }),
            )
          : [],
      })),
    };

    await createSporadicRoute({
      variables: {
        input,
      },
    }).then(() => {
      router.push(`/terminal`);
    });
  };

  const initialValues = (): Omit<
    SporadicRouteFormInput,
    'dispatcherId' | 'subcontractorId'
  > & { dispatcherId?: number; subcontractorId?: number } => ({
    isCreatedFromPlanned: false,
    isCreatedFromTerminal: true,
    transportationDate: format(getCurrentDate(), DATE_FORMAT),
    agreedPrice: null,
    currency: getCurrencyFromDepartment(department?.id),
    note: '',
    externalNote: '',
    driverName: '',
    driverPhoneNumber: '',
    licensePlate: '',
    capacity: undefined,
    routeId: '',
    dispatcherId: undefined,
    subcontractorId: undefined,
    files: [],
    invoiceNumber: undefined,
    invoiceNote: undefined,
    ftl: undefined,
    legs: getLegsForRoute(),
  });

  const getLegsForRoute = (): LegFieldInput[] => {
    return [
      {
        key: uuidv4(),
        arrivalTime: routeIsLoading
          ? getTimeOfToday(true, 1)
          : getTimeOfToday(true, 2),
        departureTime: routeIsLoading
          ? format(new Date(), TIME_FORMAT)
          : getTimeOfToday(true, 2),
        locationId: routeIsLoading
          ? location.state.locationIdOfThisTerminal
          : undefined,
        position: 0,
        note: undefined,
        load: true,
        unload: false,
        fuel: FuelType.Diesel,
        loadingListItems: [],
        transportationDate: format(getCurrentDate(), DATE_FORMAT),
        productionDate: format(getCurrentDate(), DATE_FORMAT),
        transportationDateOffset: 0,
      },
      {
        key: uuidv4(),
        arrivalTime: routeIsLoading
          ? getTimeOfToday(false, 2)
          : format(new Date(), TIME_FORMAT),
        departureTime: undefined,
        locationId: routeIsLoading
          ? undefined
          : location.state.locationIdOfThisTerminal,
        position: 1,
        fuel: FuelType.Diesel,
        note: undefined,
        load: false,
        unload: true,
        loadingListItems: [],
        transportationDate: format(getCurrentDate(), DATE_FORMAT),
        productionDate: format(getCurrentDate(), DATE_FORMAT),
        transportationDateOffset: 0,
      },
    ];
  };

  return (
    <Grid container spacing={1} direction="column">
      <HelmetComponent
        title={`${t('actions.create.capitalized')} ${t(
          'resource.sporadicRoute.capitalized',
        )}`}
      />
      <Grid item>
        <Typography variant="h1">
          {t('resource.sporadicRoute.capitalized')}
        </Typography>
        <Typography variant="h3" style={{ marginTop: 5 }}>
          {routeIsLoading ? 'Loading/Outbound' : 'Unloading/Inbound'}
        </Typography>
      </Grid>
      <Grid item>
        <TerminalRouteForm
          onSubmit={onSubmit}
          fromCreateRoute={'CreateSporadicRouteTerminal'}
          validationSchema={routeValidationSchema(t, false, true)}
          hideCostAllocation
          initialValues={initialValues()}
          submitButtonLabel={t('button.create', {
            item: t('resource.sporadicRoute.lowercased'),
          })}
        />
      </Grid>
    </Grid>
  );
}
