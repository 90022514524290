import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../lib/date_time';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { CreatedAndUpdatedView } from '../components/CreatedAndUpdatedView';
import { DialogModal } from '../components/DialogModal';
import { HelmetComponent } from '../components/HelmetComponent';
import {
  TourTemplateForm,
  TourTemplateFormInput,
} from '../components/TourTemplateForm';
import {
  FuelType,
  Maybe,
  TourRouteType,
  useDeleteTourTemplateMutation,
  useGetDepartmentsQuery,
  useGetOneTourRouteLazyQuery,
  useGetTourTemplateQuery,
  useUpdateTourTemplateMutation,
} from '../generated/graphql';
import { timeOrNull } from '../lib/date_time';
import {
  changeFromDaysArray,
  changeToDaysArray,
} from '../lib/formHelpers/days';
import {
  numberOrNull,
  _toNumberOrUndefined,
  numberOrZero,
} from '../lib/formHelpers/number';
import {
  calculatePercentage,
  calculatePercentageOfRoutePrice,
} from '../lib/percentage';
import { useHttpClient } from '../providers/HttpClientProvider';
import { updateRouteNameToValidName } from '../utils/UpdateRouteNameToValidName';
import { CreateTourPdf } from '../pdf/CreateTourPdf';
import { TourTransportAgreementInterface } from '../pdf/TransportAgreement/TourTransportAgreement';
import { calculateTourTransportationDates } from '../lib/tour_leg_dates';
import _ from 'lodash';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  header: {
    margin: theme.spacing(0.5, 0, 0.5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    borderColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.error.main} !important`,
    cursor: 'pointer',
  },
  editButton: {
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    cursor: 'pointer',
  },
}));

interface ViewTourTemplateProps {
  terminal?: boolean;
}

export function ViewTourTemplate(props: ViewTourTemplateProps) {
  const { terminal = false } = props;
  const { id } = useParams<{ id: string }>();
  const [edit, setEdit] = useState<boolean>(false);
  const { t } = useTranslation();
  const classes = useStyle();
  const { data: departmentsData } = useGetDepartmentsQuery();
  const router = useHistory();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [isNewCopy, setNewCopy] = useState<boolean>(false);
  const { httpClient } = useHttpClient();

  const handleDeleteTourTemplate = () => {
    if (tourTemplate != null) {
      deleteTourTemplate({ variables: { id: tourTemplate.id } });
      router.push('/traffic/tours');
    }
  };

  const [updateTourTemplate] = useUpdateTourTemplateMutation();
  const [deleteTourTemplate] = useDeleteTourTemplateMutation();

  const { data, loading, error } = useGetTourTemplateQuery({
    variables: {
      id: parseInt(id, 10),
    },
  });

  const [getTourRoute, { loading: tourRouteLoading, data: tourRouteData }] =
    useGetOneTourRouteLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  const location = useLocation<{
    enableEditMode: boolean;
    copyRoute: boolean;
    otherTour: boolean;
    routeId?: number;
    timestamp: number;
  }>();

  useEffect(() => {
    if (
      location.state &&
      location.state.enableEditMode &&
      location.state.copyRoute
    ) {
      setEdit(true);
      if (!location.state.otherTour)
        window.history.replaceState({}, document.title);
    }
    //for adding new route
    if (
      location.state &&
      location.state.copyRoute &&
      location.state.otherTour &&
      location.state.routeId
    ) {
      //fetch new route from query and add route to initvalue
      setNewCopy(true);
      getTourRoute({
        variables: {
          routeId: location.state.routeId,
        },
      });
    }
  }, [
    location.state?.otherTour,
    location.state?.enableEditMode,
    location.state?.copyRoute,
    location.state?.timestamp,
  ]);

  const onSubmit = async (
    values: Omit<TourTemplateFormInput, 'subcontractorId'> & {
      subcontractorId?: number;
    },
  ) => {
    const nullIfFalse = <T,>(value: T, check: boolean): T | null => {
      if (check === false) {
        return null;
      }
      return value;
    };

    if (location.state) {
      location.state.copyRoute = false;
      location.state.routeId = undefined;
    }

    await updateTourTemplate({
      variables: {
        id: parseInt(id, 10),
        input: {
          currency: values.currency,
          dispatcherId:
            typeof values.dispatcherId === 'number'
              ? values.dispatcherId
              : null,
          note: values.note,
          driverName: values.driverName,
          driverPhoneNumber: values.driverPhoneNumber,
          licensePlate: values.licensePlate,
          price: numberOrZero(values.price),
          responsiblePerson: values.responsiblePerson,
          groupingTag: values.groupingTag,
          isPriceEquallyDivided: values.isPriceEquallyDivided,
          routes: values.routes.map((route, index) => {
            let ftlObj:
              | {
                  cdc: string;
                  cmr: string;
                  price: number;
                  currency: string;
                  customerId?: Maybe<number> | undefined;
                  customerNumber: string;
                  customerReference: string;
                  invoiceNote: string;
                  __typename?: string;
                }
              | undefined = undefined;

            if (route.ftl && route.ftl != undefined) {
              ftlObj = _.cloneDeep(route.ftl);
              //No need of __typename in api
              delete ftlObj.__typename;
            }

            return {
              id: route.id,
              files: route.files
                ? route.files.map((file) => {
                    return {
                      id: file.id,
                    };
                  })
                : [],
              capacity: numberOrNull(route.capacity),
              kilometer: _toNumberOrUndefined(route.kilometer),
              weight: _toNumberOrUndefined(route.weight),
              startDate: route.startDate,
              endDate:
                route.endDate === '' || route.endDate == null
                  ? null
                  : route.endDate,
              startExceptionDate: nullIfFalse(
                route.startExceptionDate === '' ||
                  route.startExceptionDate == null
                  ? null
                  : route.startExceptionDate,
                route.hasExceptionDates,
              ),
              endExceptionDate: nullIfFalse(
                route.endExceptionDate === '' || route.endExceptionDate == null
                  ? null
                  : route.endExceptionDate,
                route.hasExceptionDates,
              ),
              exceptionDates: route.exceptionDates,
              routeDateType: route.routeDateType,
              tourRouteType: route.tourRouteType,
              position: index,
              price: numberOrZero(route.price),
              note: route.note,
              routeDriverName: route.routeDriverName,
              routeDriverPhoneNumber: route.routeDriverPhoneNumber,
              routeCarRegNumber: route.routeCarRegNumber,
              routeTrailerRegNumber: route.routeTrailerRegNumber,
              isCopyDriverPlateInfo: route.isCopyDriverPlateInfo,
              ftl: ftlObj,
              externalNote: route.externalNote ?? '',
              costAllocations: route.costAllocations.map((ca) => ({
                id: ca.id,
                departmentId: ca.departmentId,
                type: ca.type,
                includedKpi: ca.includedKpi,
                includedPrice: ca.includedPrice,
                bringCost: ca.bringCost,
                additionalCost: ca.additionalCost,
                additionalDiscount: ca.additionalDiscount,
                cost: ca.cost,
                comment: ca.comment,
              })),
              legs: route.legs.map((leg, index) => {
                return {
                  id: leg.id,
                  fuel: leg.fuel,
                  gateNumber: leg.gateNumber ?? '',
                  routeDriverName: leg.routeDriverName ?? '',
                  routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
                  carRegistrationNumber: leg.carRegistrationNumber ?? '',
                  trailerRegistrationNumber:
                    leg.trailerRegistrationNumber ?? '',
                  locationId: leg.locationId as number,
                  position: index,
                  load: leg.load,
                  unload: leg.unload,
                  arrivalTime: leg.arrivalTime ?? '00:00',
                  departureTime: timeOrNull(leg.departureTime),
                  note: leg.note ?? '',
                  transportationDateOffset: leg.transportationDateOffset,
                  loadingListItems: leg.load
                    ? leg.loadingListItems.map((loadingListItem) => ({
                        id: loadingListItem.id,
                        packages: numberOrNull(loadingListItem.packages),
                        pallets: numberOrNull(loadingListItem.pallets),
                        palletSpace: numberOrNull(loadingListItem.palletSpace),
                        weight: numberOrNull(loadingListItem.weight),
                        note: loadingListItem.note,
                        checked: loadingListItem.checked,
                        isDangerous: loadingListItem.isDangerous,
                        locationId: loadingListItem.locationId as number,
                        classification: loadingListItem.classification,
                        unNumber: loadingListItem.unNumber,
                        isLimitedQty: loadingListItem.isLimitedQty,
                      }))
                    : [],
                };
              }),
              routeId: route.routeId,
              days: changeToDaysArray(route.days),
            };
          }),
          subcontractorId: values.subcontractorId as number,
          name: values.name,
        },
      },
    });

    window.location.reload();
  };

  const tourTemplate = data?.tourTemplate;

  const handleDownloadTourSchedule = useCallback(async () => {
    if (tourTemplate != null) {
      const response = await httpClient.downloadTourSchedule(tourTemplate.id);
      const updatedName = updateRouteNameToValidName(tourTemplate.name);

      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', `Tour Schedule - ${updatedName}`);
      anchor.click();
      window.URL.revokeObjectURL(url);
    }
  }, [tourTemplate?.id]);

  if (
    loading ||
    tourTemplate == null ||
    departmentsData == null ||
    (location.state &&
      location.state.copyRoute &&
      location.state.otherTour &&
      location.state.routeId &&
      tourRouteLoading)
  ) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const todaysDate = format(new Date(), DATE_FORMAT);
  const copyFreshDataToObject = (): TourTemplateFormInput => {
    return {
      isNewVersion: true,
      currency: tourTemplate.currency,
      dispatcherId: tourTemplate.dispatcherId,
      startDate: edit ? todaysDate : tourTemplate.startDate,
      originalStartDate: tourTemplate?.originalStartDate,
      note: tourTemplate.note,
      driverName: tourTemplate.driverName,
      driverPhoneNumber: tourTemplate.driverPhoneNumber,
      licensePlate: tourTemplate.licensePlate,
      price: tourTemplate.price,
      subcontractorId: tourTemplate.subcontractorId,
      name: tourTemplate.name,
      responsiblePerson: tourTemplate.responsiblePerson,
      groupingTag: tourTemplate.groupingTag,
      isPriceEquallyDivided: isNewCopy
        ? false
        : tourTemplate.isPriceEquallyDivided,
      routes: tourTemplate.routes.map((route) => {
        const legsWithDays = calculateTourTransportationDates(route.legs);
        return {
          id: route.id,
          files: route.files,
          routeId: route.routeId,
          note: route.note,
          externalNote: route.externalNote ?? '',
          ftl: route.ftl ?? undefined,
          routeDriverName: route.routeDriverName,
          routeDriverPhoneNumber: route.routeDriverPhoneNumber,
          routeCarRegNumber: route.routeCarRegNumber,
          routeTrailerRegNumber: route.routeTrailerRegNumber,
          isCopyDriverPlateInfo: isNewCopy
            ? false
            : route.isCopyDriverPlateInfo,
          kilometer: route.kilometer,
          weight: route.weight,
          routeDateType: route.routeDateType ?? 'dr',
          tourRouteType: route.tourRouteType,
          capacity:
            route.capacity == null &&
            tourTemplate.capacity &&
            tourTemplate.capacity > 0
              ? tourTemplate.capacity
              : route.capacity,
          originalStartDate:
            route.originalStartDate && route.originalStartDate == '2020-01-01'
              ? ''
              : route.originalStartDate ?? '',
          startDate:
            (route.startDate == null || route.startDate == '2020-01-01') &&
            tourTemplate.startDate &&
            tourTemplate.startDate != ''
              ? tourTemplate.startDate
              : route.startDate,
          endDate:
            route.endDate == null &&
            tourTemplate.endDate &&
            tourTemplate.endDate != ''
              ? tourTemplate.endDate
              : route.endDate,
          startExceptionDate:
            route.startExceptionDate == null &&
            tourTemplate.startExceptionDate &&
            tourTemplate.startExceptionDate != ''
              ? tourTemplate.startExceptionDate
              : route.startExceptionDate,
          endExceptionDate:
            route.endExceptionDate == null &&
            tourTemplate.endExceptionDate &&
            tourTemplate.endExceptionDate != ''
              ? tourTemplate.endExceptionDate
              : route.endExceptionDate,
          exceptionDates:
            route.exceptionDates == null &&
            tourTemplate.exceptionDates &&
            tourTemplate.exceptionDates.length > 0
              ? tourTemplate.exceptionDates
              : route.exceptionDates ?? [],
          hasExceptionDates:
            route.startExceptionDate ||
            (route.exceptionDates && route.exceptionDates.length > 0)
              ? true
              : tourTemplate.startExceptionDate ||
                (tourTemplate.exceptionDates &&
                  tourTemplate.exceptionDates.length > 0)
              ? true
              : false,
          costAllocations: route.costAllocations.map((ca) => ({
            ...ca,
            percentage: calculatePercentage(route.price, ca.cost),
          })),
          price: route.price,
          pricePercentage: calculatePercentageOfRoutePrice(
            tourTemplate.price,
            route.price,
          ),
          days: changeFromDaysArray(route.days),
          legs: legsWithDays.map((leg) => ({
            ...leg,
            fuel:
              tourTemplate.fuel !== FuelType.Diesel &&
              tourTemplate.isNewVersion == false
                ? tourTemplate.fuel
                : leg.fuel,
            note: leg.note === '' ? undefined : leg.note,
            gateNumber: leg.gateNumber ?? '',
            carRegistrationNumber: leg.carRegistrationNumber ?? '',
            trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
            routeDriverName: leg.routeDriverName ?? '',
            routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
            key: leg.id.toString(),
            daysDiff: leg.daysDiff,
            transportationDate: null,
            loadingListItems: leg.loadingListItems.map((lli) => {
              return {
                ...lli,
                packages: lli.packages ?? '',
                pallets: lli.pallets ?? '',
                palletSpace: lli.palletSpace ?? '',
                weight: lli.weight ?? '',
                classification: lli.classification ?? undefined,
              };
            }),
          })),
        };
      }),
      id,
    };
  };

  const initPDFObject = (): TourTransportAgreementInterface => {
    return {
      currency: tourTemplate.currency,
      tourAgreementNo: tourTemplate.tourAgreementNo ?? 1,
      dispatcherName: tourTemplate.dispatcher?.name,
      note: tourTemplate.note,
      licensePlate: tourTemplate.licensePlate,
      price: tourTemplate.price,
      subcontractorName: tourTemplate.subcontractor?.name,
      name: tourTemplate.name,
      createdBy: tourTemplate.createdBy,
      routes: tourTemplate.routes.map((route) => {
        return {
          id: route.id,
          capacity:
            route.capacity == null &&
            tourTemplate.capacity &&
            tourTemplate.capacity > 0
              ? tourTemplate.capacity
              : route.capacity,
          kilometer: route.kilometer,
          weight: route.weight,
          originalStartDate:
            route.originalStartDate && route.originalStartDate == '2020-01-01'
              ? ''
              : route.originalStartDate ?? '',
          startDate:
            (route.startDate == null || route.startDate == '2020-01-01') &&
            tourTemplate.startDate &&
            tourTemplate.startDate != ''
              ? tourTemplate.startDate
              : route.startDate,
          endDate:
            route.endDate == null &&
            tourTemplate.endDate &&
            tourTemplate.endDate != ''
              ? tourTemplate.endDate
              : route.endDate,
          startExceptionDate:
            route.startExceptionDate == null &&
            tourTemplate.startExceptionDate &&
            tourTemplate.startExceptionDate != ''
              ? tourTemplate.startExceptionDate
              : route.startExceptionDate,
          endExceptionDate:
            route.endExceptionDate == null &&
            tourTemplate.endExceptionDate &&
            tourTemplate.endExceptionDate != ''
              ? tourTemplate.endExceptionDate
              : route.endExceptionDate,
          exceptionDates:
            route.exceptionDates == null &&
            tourTemplate.exceptionDates &&
            tourTemplate.exceptionDates.length > 0
              ? tourTemplate.exceptionDates
              : route.exceptionDates ?? [],
          hasExceptionDates:
            route.startExceptionDate ||
            (route.exceptionDates && route.exceptionDates.length > 0)
              ? true
              : tourTemplate.startExceptionDate ||
                (tourTemplate.exceptionDates &&
                  tourTemplate.exceptionDates.length > 0)
              ? true
              : false,
          files: route.files,
          routeId: route.routeId,
          note: route.note,
          externalNote: route.externalNote ?? '',
          price: route.price,
          days: route.days,
          legs: route.legs.map((leg) => ({
            ...leg,
            note: leg.note === '' ? undefined : leg.note,
            key: leg.id.toString(),
          })),
        };
      }),
      id,
    };
  };

  const objectWithCopiedRoute = (): TourTemplateFormInput => {
    const finalObj: TourTemplateFormInput = copyFreshDataToObject();

    if (
      location.state &&
      location.state.copyRoute &&
      location.state.otherTour &&
      location.state.routeId &&
      tourRouteData
    ) {
      if (tourRouteData?.getTourRoute) {
        const route = tourRouteData?.getTourRoute;

        finalObj.routes.push({
          id: undefined,
          files: route.files,
          routeId: '',
          note: route.note,
          externalNote: route.externalNote ?? '',
          capacity: route.capacity,
          kilometer: route.kilometer,
          weight: route.weight,
          costAllocations: route.costAllocations.map((ca) => {
            return {
              ...ca,
              id: undefined,
              percentage: calculatePercentage(route.price, ca.cost),
            };
          }),
          price: route.price,
          days: changeFromDaysArray(route.days),
          routeDriverName: route.routeDriverName,
          routeDriverPhoneNumber: route.routeDriverPhoneNumber,
          routeCarRegNumber: route.routeCarRegNumber,
          routeTrailerRegNumber: route.routeTrailerRegNumber,
          isCopyDriverPlateInfo: route.isCopyDriverPlateInfo,
          hasExceptionDates: false,
          startDate: format(new Date(), 'yyyy-MM-dd'),
          tourRouteType: TourRouteType.Old,
          legs: route.legs.map((leg) => ({
            ...leg,
            id: undefined,
            note: leg.note === '' ? undefined : leg.note,
            routeDriverName: leg.routeDriverName ?? '',
            routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
            carRegistrationNumber: leg.carRegistrationNumber ?? '',
            trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
            fuel: leg.fuel,
            loadingListItems: leg.loadingListItems.map((lli) => {
              return {
                ...lli,
                id: undefined,
                packages: lli.packages ?? '',
                pallets: lli.pallets ?? '',
                palletSpace: lli.palletSpace ?? '',
                weight: lli.weight ?? '',
              };
            }),
          })),
        });
      }
    } else if (
      location.state &&
      location.state.copyRoute &&
      location.state.otherTour &&
      location.state.routeId &&
      (tourRouteData == null || tourRouteData == undefined)
    ) {
      window.history.replaceState({}, document.title);
    }

    return finalObj;
  };

  return (
    <Grid container spacing={1} direction="column">
      <HelmetComponent title={tourTemplate.name} />
      <Grid container justifyContent="space-between">
        <Box className={classes.header}>
          <Typography variant="h1">{`${t('resource.tour.capitalized')} ${t(
            'resource.template.capitalized',
          )}: ${tourTemplate.name}`}</Typography>
        </Box>
        {!terminal && (
          <Grid item justifyContent="space-between">
            <Box>
              {!edit && (
                <Tooltip title={`${t('actions.copyTour')}`}>
                  <IconButton>
                    <Link to={`/traffic/tours/create/${tourTemplate.id}`}>
                      <ContentCopyIcon color="action" />
                    </Link>
                  </IconButton>
                </Tooltip>
              )}
              {/* edit tour template icon */}
              <Tooltip
                title={edit ? `${t('actions.cancel')}` : `${t('actions.edit')}`}
              >
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => {
                    if (
                      location.state &&
                      location.state.copyRoute &&
                      location.state.otherTour &&
                      location.state.routeId &&
                      tourRouteData &&
                      isNewCopy
                    ) {
                      setNewCopy(false);
                    }
                    setEdit(!edit);
                  }}
                >
                  {edit ? <CancelIcon /> : <EditIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <CreatedAndUpdatedView
          createdBy={tourTemplate.createdBy}
          lastUpdatedBy={tourTemplate.lastUpdatedBy}
          updatedAt={tourTemplate.updatedAt}
        />
      </Grid>
      <Box mt={1}>
        {edit && !terminal && (
          <Button
            classes={{
              root: classes.button,
            }}
            variant="outlined"
            startIcon={<DeleteIcon />}
            endIcon={<WarningIcon />}
            onClick={() => {
              if (tourTemplate) {
                setOpenDeleteDialog(true);
              }
            }}
          >
            {t('button.delete', {
              item: `${t('resource.tour.capitalized')} ${t(
                'resource.template.capitalized',
              )}`,
            })}
          </Button>
        )}
        <DialogModal
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          buttonText={t('actions.delTour')}
          contentText={t('validation.confirmation', {
            item: tourTemplate.name,
            action: t('actions.deleteTour'),
          })}
          doAction={handleDeleteTourTemplate}
        />
      </Box>

      {!edit && (
        <Grid item>
          <CreateTourPdf tour={initPDFObject()} />
          <Button
            variant="contained"
            startIcon={<CloudDownloadIcon />}
            onClick={handleDownloadTourSchedule}
          >
            {t('actions.download', {
              item: t('resource.tourSchedule.capitalized'),
            })}
          </Button>
        </Grid>
      )}

      <Grid item>
        <TourTemplateForm
          terminal={terminal}
          onSubmit={onSubmit}
          initialValues={
            isNewCopy ? objectWithCopiedRoute() : copyFreshDataToObject()
          }
          submitButtonLabel={t('button.update', {
            item: `${t('resource.tour.capitalized')} ${t(
              'resource.template.capitalized',
            )}`,
          })}
          readOnly={!edit}
          departments={departmentsData.departments}
        />
      </Grid>
    </Grid>
  );
}
