import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import OkIcon from '@mui/icons-material/CheckCircle';
import NotOkIcon from '@mui/icons-material/Cancel';

import {
  EditShipmentLoadingList,
  ShipmentEditInput,
  useDeleteShipmentMutation,
  useEditShipmentMutation,
  useGetShipmentByIdQuery,
  useUpdateLoadingListItemMutation,
  CustomsProcedure,
  useShipmentPartialUpdateSubSubscription,
} from '../../generated/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { UserConfiguredDataGridPro } from '../datagrid/UserConfiguredDataGridPro';
import { TABLE_NAMES } from '../../lib/constants';
import {
  DataGridProProps,
  GridCellEditCommitParams,
  GridCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { SelectLocationCell } from '../datagrid/SelectLocationCell';
import { Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { CommonTextField } from '../form/CommonTextField';
import { CommonDateField } from '../form/CommonDateField';
import { SelectLocation } from '../form/SelectLocation';
import { ShipmentOrderModal } from '../modal/ShipmentOrderModal';
import { EditableCell } from './EditableCell';
import { DetailPanelContent } from './DetailPanelContent';
import * as yup from 'yup';

const shipmentObjValidation = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(3)
      .required(t('validation.isRequired', { name: t('attributes.name') })),
    date: yup
      .date()
      .typeError('Date is required')
      .nullable()
      .when('isTemplate', {
        is: false,
        then: yup
          .date()
          .typeError('This is required')
          .required(
            t('validation.isRequired', {
              name: t('filter.date'),
            }),
          ),
      }),
    fromLocationId: yup
      .number()
      .min(
        1,
        t('validation.isRequired', {
          name: t('attributes.location'),
        }),
      )
      .required(t('validation.isRequired', { name: t('attributes.terminal') })),
    toLocationId: yup
      .number()
      .required(t('validation.isRequired', { name: t('attributes.terminal') }))
      .positive(t('validation.isRequired', { name: t('attributes.terminal') }))
      .min(
        1,
        t('validation.isRequired', {
          name: t('attributes.terminal'),
        }),
      ),
  });

const useStylesForLoadingList = makeStyles(() =>
  createStyles({
    editableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& p:first-child': {
        maxWidth: '75%',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        inlineSize: '75%',
      },
    },
    greenChecked: {
      backgroundColor: '#d3f8d3',
    },
    redBorder: {
      border: '1px solid red',
    },
    greyBackFocused: {
      backgroundColor: '#f1f5eb',
      paddingTop: 2,
      paddingBottom: 2,
    },
    blueBackReadOnly: {
      backgroundColor: '#d1e7fc',
      paddingTop: 2,
      paddingBottom: 2,
    },
    root: {
      margin: 0,
      padding: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    deleteButton: {
      padding: '0 !important',
      marginRight: 1,
    },
    listItem: {
      '&.MuiListItem': {
        padding: 0,
      },
      '&.MuiListItem-gutters': {
        padding: 0,
      },
      '.MuiListItem-root': {
        padding: 0,
      },
    },
    numberFields: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    selecLocationField: {
      width: '98%',
      fontSize: '10px',
      margin: 0,
      padding: 0,
    },
    field: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    iconButton: {
      padding: '0 !important',
      marginRight: 10,
    },
  }),
);

//below values are not stored to DB
export interface EditLoadingList extends EditShipmentLoadingList {
  addNote: boolean;
  isCustom: boolean;
  fileAttachment: boolean;
  isTerminal: boolean;
  routeName?: Maybe<string>;
  locationName: string;
  isReadOnly?: boolean;
}

interface ShipmentLLProps {
  updatedFrom: string;
}

export function ShipmentLoadingList(props: ShipmentLLProps) {
  const { updatedFrom } = props;
  const router = useHistory();
  const { id: shipmentId } = useParams<{ id: string }>();
  const selectedDarkGreen = '#3c9317';
  const dangerRed = '#ff0000';
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const classes = useStylesForLoadingList();
  const [updateShipmentModal] = useEditShipmentMutation();
  const [updateLoadingListItem] = useUpdateLoadingListItemMutation();
  const [deleteShipment] = useDeleteShipmentMutation();
  const [viewOrderModal, setViewOrderModal] = useState(false);
  const [isUpdateInProgress, setUpdateInProgress] = useState(false);
  const [initObj, setInitObj] = useState<{
    date?: string | null;
    name: string;
    loadingListValue: EditLoadingList[];
    isTemplate: boolean;
    fromLocationId: number;
    toLocationId: number;
  }>({
    date: '',
    name: '',
    loadingListValue: [],
    isTemplate: false,
    fromLocationId: 0,
    toLocationId: 0,
  });
  const [readOnly, setReadOnly] = useState(false);
  const { data, refetch, loading } = useGetShipmentByIdQuery({
    variables: { id: +shipmentId },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.shipmentById) {
      const loadingList: EditLoadingList[] = [];
      const shipmentDateStr = data.shipmentById.shipmentDate ?? '2020-01-01';
      const todayDate = new Date().setHours(0, 0, 0, 0);
      const shipmentDateDate = new Date(shipmentDateStr).setHours(0, 0, 0, 0);
      const isPastShipmentDate = todayDate > shipmentDateDate;
      if (data.shipmentById.loadingListItems) {
        data.shipmentById.loadingListItems.forEach((list) => {
          loadingList.push({
            id: list.id,
            locationId: list.locationId,
            locationName: list.location?.name,
            note: list.note,
            checked: list.checked,
            isDangerous: list.isDangerous,
            classification: list.classification,
            unNumber: list.unNumber,
            isLimitedQty: list.isLimitedQty,
            pallets: list.pallets,
            packages: list.packages,
            palletSpace: list.palletSpace,
            connectedNB: list.connectedNB,
            customCmrNo: list.customCmrNo,
            customInvoiceNo: list.customInvoiceNo,
            customMrnNo: list.customMrnNo,
            customNote: list.customNote,
            customVOECId: list.customVOECId,
            customWaybillNo: list.customWaybillNo ?? '',
            customCleared: list.customCleared,
            customClearedForImport: list.customClearedForImport,
            isRemainingGoods: list.isRemainingGoods,
            isVoecCustomer: list.isVoecCustomer,
            customsProcedure: list.customsProcedure ?? undefined,
            terminalCleared: list.terminalCleared,
            terminalNotCleared: list.terminalNotCleared,
            terminalShipmentNotArrived: list.terminalShipmentNotArrived,
            terminalDevInfo: list.terminalDevInfo,
            terminalDevPackages: list.terminalDevPackages,
            terminalDevPallets: list.terminalDevPallets,
            terminalDevPalletSpace: list.terminalDevPalletSpace,
            terminalDevWeight: list.terminalDevWeight,
            isImportedFromShipment:
              list.isImportedFromShipment &&
              list.routeDetail &&
              list.routeDetail.routeName != '',
            addNote: false,
            isCustom: false,
            fileAttachment: false,
            isTerminal: false,
            routeName: list.routeDetail ? list.routeDetail.routeName : '',
            isReadOnly: isPastShipmentDate,
          });
        });
      }
      //Any one item is imported OR past date shipment And not template
      setReadOnly(
        loadingList.some((list) => list.isImportedFromShipment) ||
          isPastShipmentDate,
      );
      const loaddingListOrderBy = _.orderBy(
        loadingList,
        (item) => item.locationName,
        ['asc'],
      );
      //setPartialImport()
      setInitObj({
        date: data.shipmentById.shipmentDate,
        name: data.shipmentById.name,
        loadingListValue: loaddingListOrderBy,
        isTemplate: data.shipmentById.shipmentDate ? false : true,
        fromLocationId: data.shipmentById.fromLocationId,
        toLocationId: data.shipmentById.toLocationId,
      });
    }
  }, [data]);

  useShipmentPartialUpdateSubSubscription({
    variables: {
      shipmentId: +shipmentId,
      userId: '-',
    },
    async onSubscriptionData() {
      refetch({ id: +shipmentId });
    },
  });

  const handleCellEditCommit = useCallback(
    async ({ id, field, value }: GridCellEditCommitParams) => {
      if (field == 'locationId' && typeof value !== 'number') {
        return null;
      }
      updateLoadingListItem({
        variables: {
          input: {
            id: +id,
            palletSpace: field == 'palletSpace' ? value : undefined,
            checked: field == 'checked' ? value : undefined,
            locationId:
              field == 'locationId' ? parseInt(value as string, 10) : undefined,
            note: field == 'note' ? value : undefined,
            packages: field == 'packages' ? value : undefined,
            pallets: field == 'pallets' ? value : undefined,
            customsProcedure: field == 'customsProcedure' ? value : undefined,
            customWaybillNo:
              field == 'customWaybillNo' ? `${value}` : undefined,
          },
          shipmentId: +shipmentId,
        },
      });
    },
    [initObj.loadingListValue],
  );

  const handleAddOrder = (value: EditShipmentLoadingList[]) => {
    if (data && data?.shipmentById.loadingListItems?.length > 0) {
      data.shipmentById.loadingListItems.forEach((ll) => {
        value.push({
          id: ll.id,
          checked: ll.checked,
          customCleared: ll.customCleared,
          customClearedForImport: ll.customClearedForImport,
          isDangerous: ll.isDangerous,
          isRemainingGoods: ll.isRemainingGoods,
          customsProcedure: ll.customsProcedure,
          isVoecCustomer: ll.isVoecCustomer,
          locationId: ll.locationId,
          note: ll.note,
          terminalCleared: ll.terminalCleared,
          terminalNotCleared: ll.terminalNotCleared,
          terminalShipmentNotArrived: ll.terminalShipmentNotArrived,
          classification: ll.classification,
          isLimitedQty: ll.isLimitedQty,
          packages: ll.packages,
          pallets: ll.pallets,
          palletSpace: ll.palletSpace,
          unNumber: ll.unNumber,
        });
      });
    }
    const obj: ShipmentEditInput = {
      id: +shipmentId,
      name: initObj.name,
      shipmentDate: initObj.date,
      fromLocationId: initObj.fromLocationId,
      toLocationId: initObj.toLocationId,
      updatedFrom: updatedFrom,
      loadingListItems: value,
    };
    updateShipmentModal({
      variables: { input: obj, isNewOrders: true },
    }).then(() => {
      refetch({ id: +shipmentId });
    });
  };

  const handleCellDoubleClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (params.row.isReadOnly) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [],
  );
  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row }) => {
      const shipmentList = initObj.loadingListValue.filter(
        (ll) => ll.id == row.id,
      );
      return (
        <DetailPanelContent
          shipmentList={shipmentList[0]}
          shipmentDate={initObj.date ?? ''}
          shipmentId={+shipmentId}
        />
      );
    },
    [initObj],
  );

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <Grid item container direction="row-reverse">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            deleteShipment({
              variables: {
                id: +shipmentId,
                updatedFrom,
                shipmentDate: initObj.date ?? '',
              },
            }).then(() => {
              router.push(`/traffic/shipment`);
            });
          }}
        >
          {'Delete Shipment'}
        </Button>
      </Grid>
      {initObj.fromLocationId && (
        <Formik
          initialValues={{
            name: initObj.name,
            date: initObj.date,
            fromLocationId: initObj.fromLocationId,
            toLocationId: initObj.toLocationId,
          }}
          validate={(value) => {
            try {
              validateYupSchema(value, shipmentObjValidation(t), true, value);
            } catch (err) {
              const errors = yupToFormErrors<{ error: string }>(err);
              return errors;
            }
            return {};
          }}
          validateOnBlur
          validateOnChange={false}
          onSubmit={(value) => {
            // we changed object type of loadingListValue by adding isTerminal and isCustom boolean so we can't directly
            // assign initObj.loadingListValue to loadingListItems and here we are not changin loadingListItems, we handle
            //loading list item in backend
            const obj: ShipmentEditInput = {
              id: +shipmentId,
              name: value.name,
              shipmentDate: value.date,
              fromLocationId: value.fromLocationId,
              toLocationId: value.toLocationId,
              updatedFrom: updatedFrom,
              loadingListItems: [],
            };
            setUpdateInProgress(true);
            updateShipmentModal({
              variables: { input: obj, orderListUnchanged: true },
            }).then(() => {
              setUpdateInProgress(false);
              refetch({ id: +shipmentId });
            });
          }}
        >
          {() => {
            return (
              <Form>
                <Box
                  style={{
                    border: '1px solid green',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Grid container spacing={1} style={{ marginTop: 10 }}>
                    <Grid item xs={5}>
                      <SelectLocation
                        name={'fromLocationId'}
                        fullWidth
                        size="small"
                        label={'toTerminal'}
                        readOnly={readOnly}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <SelectLocation
                        name={'toLocationId'}
                        fullWidth
                        size="small"
                        label={'toTerminal'}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginTop: 10, marginBottom: 20 }}
                    spacing={1}
                  >
                    <Grid item xs={'auto'}>
                      <CommonTextField name="name" readOnly={readOnly} />
                    </Grid>
                    <Grid item xs={2}>
                      <CommonDateField
                        name="date"
                        variant="outlined"
                        readOnly={readOnly}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={'auto'}>
                      {readOnly ? null : (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isUpdateInProgress}
                        >
                          {`${
                            isUpdateInProgress
                              ? t('validation.loadingApi')
                              : t('actions.update')
                          }`}
                        </Button>
                      )}

                      {readOnly ? (
                        <Typography>{'ReadOnly due to Past date!'}</Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
      <Grid item container direction="row-reverse" style={{ marginBottom: 5 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setViewOrderModal(!viewOrderModal);
          }}
          disabled={readOnly}
        >
          {'Add Order'}
        </Button>
      </Grid>
      <UserConfiguredDataGridPro
        tableName={TABLE_NAMES.ShipmentGrid}
        apiRef={apiRef}
        onCellEditCommit={handleCellEditCommit}
        rows={initObj.loadingListValue ?? []}
        columns={[
          { field: 'id', headerName: 'Id', disableColumnMenu: true },
          {
            field: 'terminalCleared',
            headerName: 'Terminal cleared',
            type: 'actions',
            align: 'center',
            sortable: false,
            renderCell(params) {
              const { value } = params;
              return (
                <Grid
                  item
                  container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Tooltip title="Terminal cleared?">
                    <IconButton className={classes.iconButton}>
                      {value ? (
                        <OkIcon htmlColor={selectedDarkGreen} />
                      ) : (
                        <NotOkIcon htmlColor={dangerRed} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              );
            },
          },
          {
            field: 'customCleared',
            headerName: 'Custom cleared',
            type: 'actions',
            align: 'center',
            sortable: false,
            width: 100,
            renderCell: (params) => {
              return (
                <Grid
                  item
                  container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Tooltip title="Custom cleared?">
                    <IconButton className={classes.iconButton}>
                      {params.value && params.row.customClearedForImport ? (
                        <OkIcon htmlColor={selectedDarkGreen} />
                      ) : (
                        <NotOkIcon htmlColor={dangerRed} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              );
            },
          },
          {
            field: 'locationId',
            headerName: 'Customer',
            width: 200,
            editable: true,
            sortable: false,
            valueGetter({ row }) {
              return `${row.locationName}`;
            },
            renderEditCell(params) {
              const { id, field, row } = params;
              const updateCellValue = (
                location?: {
                  id: number;
                  name: string;
                } | null,
              ) => {
                if (location?.id)
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: location?.id,
                  });
              };

              if (params.row.isReadOnly) return null;
              return (
                <SelectLocationCell
                  onCellChange={updateCellValue}
                  initialValue={row.locationId}
                />
              );
            },
          },
          {
            field: 'packages',
            headerName: 'Packages',
            type: 'number',
            editable: true,
            renderCell(params) {
              return (
                <EditableCell
                  apiRef={apiRef}
                  editable={!params.row.isReadOnly}
                  className={classes.editableCell}
                  {...params}
                />
              );
            },
          },
          {
            field: 'pallets',
            headerName: 'Pallets',
            type: 'number',
            editable: true,
            renderCell(params) {
              return (
                <EditableCell
                  apiRef={apiRef}
                  editable={!params.row.isReadOnly}
                  className={classes.editableCell}
                  {...params}
                />
              );
            },
          },
          {
            field: 'palletSpace',
            headerName: 'Pallet space',
            type: 'number',
            editable: true,
            renderCell(params) {
              return (
                <EditableCell
                  apiRef={apiRef}
                  editable={!params.row.isReadOnly}
                  className={classes.editableCell}
                  {...params}
                />
              );
            },
          },
          {
            field: 'customWaybillNo',
            headerName: 'Custom way bill No.',
            type: 'number',
            editable: true,
            renderCell(params) {
              return (
                <EditableCell
                  apiRef={apiRef}
                  editable={!params.row.isReadOnly}
                  className={classes.editableCell}
                  {...params}
                />
              );
            },
          },
          {
            field: 'routeName',
            headerName: 'Route name',
            editable: false,
          },
          {
            field: 'customsProcedure',
            headerName: 'Customs procedure',
            editable: true,
            type: 'singleSelect',
            width: 140,
            valueOptions: Object.values(CustomsProcedure).map(
              (option) => option,
            ),
            renderCell: (params) => (
              <Grid
                item
                container
                alignItems="center"
                alignContent="center"
                direction="row-reverse"
              >
                {params.row.isReadOnly ? null : (
                  <IconButton
                    onClick={() => {
                      apiRef.current.setCellMode(
                        params.id,
                        params.field,
                        'edit',
                      );
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {params.value}
              </Grid>
            ),
          },
          {
            headerName: 'Is Remaining Goods',
            field: 'isRemainingGoods',
            minWidth: 100,
            type: 'actions',
            align: 'center',
            editable: true,
            sortable: false,
            renderCell: (params: GridCellParams) => {
              const { value, id } = params;
              return (
                <Checkbox
                  style={{ padding: 0, color: '#ff0202b8' }}
                  checked={value}
                  size="small"
                  onClick={() => {
                    updateLoadingListItem({
                      variables: {
                        input: {
                          id: +id,
                          isRemainingGoods: !value,
                        },
                        shipmentId: +shipmentId,
                      },
                    });
                  }}
                  disabled={params.row.isReadOnly}
                />
              );
            },
          },
          {
            field: 'note',
            headerName: 'Note',
            editable: true,
            renderCell(params) {
              return (
                <EditableCell
                  apiRef={apiRef}
                  editable={!params.row.isReadOnly}
                  className={classes.editableCell}
                  {...params}
                />
              );
            },
          },
        ]}
        onCellDoubleClick={handleCellDoubleClick}
        onCellClick={handleCellDoubleClick}
        autoHeight
        // rowHeight={50}
        disableSelectionOnClick
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
      />

      <ShipmentOrderModal
        showModal={viewOrderModal}
        from={updatedFrom}
        onClose={() => {
          setViewOrderModal(false);
        }}
        onSubmit={(value) => {
          handleAddOrder(value);
        }}
      />
    </>
  );
}
