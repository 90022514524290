import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { Maybe } from '../generated/graphql';
import { getUserName } from '../lib/useRoles';
import { CenteredBox } from './CenteredBox';
import { CostAllocationItemInterface } from './form/CostAllocationFields';
import { LegFieldInput } from './form/LegFields';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { format, isWithinInterval } from 'date-fns';
import { getDayNumber } from '../views/CreateMultipleSporadicRouteRHF';
import { DialogConfirmationModal } from './DialoConfirmationgModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, UseFormSetValue } from 'react-hook-form';
import { DatesFieldsRHF } from './form/tourFields/DatesFieldsRHF';
import { AccordionContainerRHF } from './form/AccordionContainerRHF';
import { DaysFieldForPlannedRoutesRHF } from './form/tourFields/DaysFieldForPlannedRoutesRHF';
import DatePickerMultipleRHF from './form/tourFields/DatePickerMultipleRHF';
import { RouteAgreementFieldsRHF } from './form/routeFields/RouteAgreementFieldsRHF';
import { CommonTextFieldRHF } from './form/CommonTextFieldRHF';
import { SubcontractorFieldsRHF } from './form/SubcontractorFieldsRHF';
import { HideableFormFieldsRHF } from './form/HideableFormFieldsRHF';
import { RouteFTLFieldsRHF } from './form/RouteFTLFieldsRHF';
import { CostAllocationFieldsRHF } from './form/CostAllocationFieldsRHF';
import { LegsFieldsRHF } from './LegsFieldsRHF';
import { AutoResetRHF } from '../utils/AutoResetRHF';
import { AggregatesLoadingListRHF } from './AggregatesLoadingListRHF';
import { FilesFieldRHF } from './form/FilesFieldRHF';
import DriverInfoCarIcon from '@mui/icons-material/DirectionsCarFilled';
import { CopyDriverFieldsRouteRHF } from './form/CopyDriverFieldsRouteRHF';

function createFTL() {
  return {
    cdc: '',
    cmr: '',
    customerId: undefined,
    customerNumber: '',
    customerReference: '',
    invoiceNote: '',
    price: 0,
    currency: 'dkk',
  };
}

export type SporadicRouteFormInput = {
  dateType?: string;
  selectedCalDates?: string[];
  hasExceptionDates?: boolean;
  startDate?: string;
  endDate?: string;
  startExceptionDate?: string;
  endExceptionDate?: string;
  selectedDays?: {
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
  };
  isUpdateAndClose?: boolean;
  isCreatedFromPlanned: boolean;
  isCreatedFromTerminal: boolean;
  transportationDate: string;
  agreedPrice?: Maybe<number>;
  currency: string;
  note: string;
  externalNote: string;
  driverName: string;
  driverPhoneNumber: string;
  licensePlate: string;
  capacity?: Maybe<number>;
  kilometer?: Maybe<number>;
  weight?: Maybe<number>;
  routeId: string;
  invoiceNumber: Maybe<string> | undefined;
  invoiceNote: Maybe<string> | undefined;
  subcontractorId?: number;
  dispatcherId?: number;
  totalBringCost: number;
  newAgreedPrice: number;
  finalEveryCost: number;
  finalAdditionalCost: number;
  costAllocation?: Maybe<{
    id?: number;
    items: CostAllocationItemInterface[];
  }>;
  files: {
    id: number;
    originalname: string;
    path: string;
    createdBy: string;
    createdAt: Date;
  }[];
  ftl:
    | {
        cdc: string;
        cmr: string;
        price: number;
        currency: string;
        customerId?: Maybe<number>;
        customerNumber: string;
        customerReference: string;
        invoiceNote: string;
      }
    | undefined;
  routeDriverName?: Maybe<string>;
  routeDriverPhoneNumber?: Maybe<string>;
  routeCarRegNumber?: Maybe<string>;
  routeTrailerRegNumber?: Maybe<string>;
  isCopyDriverPlateInfo: boolean;
  legs: LegFieldInput[];
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  field: {
    width: '100%',
    margin: theme.spacing(1),
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(1, 'auto'),
  },
  subResources: {
    margin: theme.spacing(1, 'auto'),
    width: '95%',
  },
  button: {
    margin: theme.spacing(1, 'auto'),
  },
  formContent: {
    margin: theme.spacing(1, 0, 2, 1),
    padding: theme.spacing(0, 2, 2, 2),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      padding: theme.spacing(1, 0),
    },

    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
  legField: {
    width: '100%',
    fontSize: '10px',
    marginRight: 20,
    marginTop: 18,
    marginBottom: 18,
  },
}));

interface RouteFormProps {
  initialValues: SporadicRouteFormInput;
  onSubmit: (values: SporadicRouteFormInput) => void;
  getFormMethods?: (methods: {
    setFieldValue: UseFormSetValue<SporadicRouteFormInput>;
    isSubmitting: boolean;
  }) => void;
  submitButtonLabel: string;
  readOnly?: boolean;
  includeInvoice?: boolean;
  terminal?: boolean;
  hideCostAllocation?: boolean;
  validationSchema: ObjectSchema<ObjectShape>;
  fromCreateRoute?: string;
  formRef: any;
  legIdForLoadingList?: number;
  enableEditMode?: boolean;
}

export function RouteFormRHF(props: RouteFormProps) {
  const {
    initialValues,
    onSubmit,
    getFormMethods,
    submitButtonLabel,
    readOnly = false,
    includeInvoice = false,
    terminal = false,
    hideCostAllocation = false,
    validationSchema,
    fromCreateRoute,
    formRef = null,
    legIdForLoadingList,
    enableEditMode,
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const user = getUserName();
  const MAX_ROUTES_ALLOWED = 365;
  const SELECTED_DATE_FORMAT = 'dd-MMM-yyyy';
  const FROM_CREATE_PLANNED = 'CreateMultipleSporadicRoute';

  const allFormMethods = useForm<SporadicRouteFormInput>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue: setFieldValue,
    getValues,
  } = allFormMethods;
  if (getFormMethods != undefined) {
    getFormMethods({
      setFieldValue,
      isSubmitting,
    });
  }

  const collapseSections = useCallback(() => {
    if (localStorage.getItem('collapseTruckSections_' + user) === 'true') {
      return true;
    } else {
      return false;
    }
  }, [localStorage.getItem('collapseTruckSections_' + user)]);
  const [aggreementOpen, setAggreementOpen] = useState<boolean>(
    !collapseSections(),
  );
  const [driverInfoOpen, setDriverInfoOpen] = useState<boolean>(
    fromCreateRoute !== 'ViewRoute',
  );
  const [noteOpen, setNoteOpen] = useState<boolean>(!collapseSections());
  const [subcontractorOpen, setSubcontractorOpen] = useState<boolean>(
    !collapseSections(),
  );
  const [invoiceOpen, setInvoiceOpen] = useState<boolean>(!collapseSections());
  const terminalORreadonly = terminal || readOnly;

  const [costAllocationOpen, setCostAllocationOpen] = useState<boolean>(false);
  const [filesOpen, setFilesOpen] = useState<boolean>(true);
  const [ftlOpen, setFtlOpen] = useState<boolean>(
    initialValues && initialValues.ftl ? true : false,
  );
  const [submitAfterConfirmation, setIsSubmitConfirmation] =
    useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [alertError, setAlertError] = useState<boolean>(false);

  const [alertLoading, setAlertLoading] = useState<boolean>(false);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState('dr');

  function calculateDatesAndSubmit(values: SporadicRouteFormInput) {
    const routeId = values.routeId.trim().replaceAll('  ', ' ').toUpperCase();
    let finalDatesArr: string[] = [];
    const finalDays: number[] = [];

    //calculate dates
    if (values.dateType && values.dateType === 'dr') {
      const startDate =
        values.startDate && values.startDate != ''
          ? new Date(values.startDate)
          : null;
      const endDate =
        values.endDate && values.endDate != ''
          ? new Date(values.endDate)
          : null;

      const selDays: any = values.selectedDays ?? null;

      if (selDays) {
        for (const key in selDays) {
          if (key && Boolean(selDays[key]) === true) {
            finalDays.push(getDayNumber(key.toString()));
          }
        }
      }

      if (startDate != null && endDate != null) {
        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
          const loopDay = new Date(d);

          if (values.hasExceptionDates) {
            const startExcDate =
              values.startExceptionDate && values.startExceptionDate != ''
                ? new Date(values.startExceptionDate)
                : null;
            const endExcDate =
              values.endExceptionDate && values.endExceptionDate != ''
                ? new Date(values.endExceptionDate)
                : null;

            if (
              startExcDate != null &&
              endExcDate != null &&
              !isWithinInterval(loopDay, {
                start: startExcDate,
                end: endExcDate,
              }) &&
              finalDays.includes(loopDay.getDay())
            ) {
              finalDatesArr.push(format(loopDay, SELECTED_DATE_FORMAT));
            }
          } else if (finalDays.includes(loopDay.getDay())) {
            finalDatesArr.push(format(loopDay, SELECTED_DATE_FORMAT));
          }
        }
      }
    } else {
      finalDatesArr = values.selectedCalDates ?? [];
    }

    if (finalDatesArr.length === 0) {
      setErrorMessage(`${t('validation.plannedRoutesErrorDate')}`);
      setAlertError(true);
    } else if (finalDatesArr.length > MAX_ROUTES_ALLOWED) {
      setErrorMessage(`${t('validation.plannedRoutesErrorRange')}`);
      setAlertError(true);
    } else {
      const newValues = {
        ...values,
        routeId,
        selectedCalDates: finalDatesArr,
      };
      if (submitAfterConfirmation === false) {
        setIsSubmitConfirmation(true);
        setOpenCreateDialog(true);
      } else {
        setAlertLoading(true);
        onSubmit(newValues);
        setIsSubmitConfirmation(false);
      }
    }
  }

  return (
    <Paper className={classes.formContent}>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore */}
      <FormProvider {...allFormMethods}>
        <form
          ref={formRef}
          onSubmit={
            fromCreateRoute === FROM_CREATE_PLANNED
              ? handleSubmit(calculateDatesAndSubmit)
              : handleSubmit(onSubmit)
          }
        >
          <Grid container>
            <AutoResetRHF readOnly={readOnly} />
            {fromCreateRoute === FROM_CREATE_PLANNED ? (
              <Grid item xs={12}>
                <div>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    open={alertError}
                    key={'alertError'}
                    autoHideDuration={3000}
                    onClose={() => {
                      setAlertError(false);
                    }}
                  >
                    <Alert
                      onClose={() => {
                        setAlertError(false);
                      }}
                      severity="error"
                    >
                      {errorMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    open={alertLoading}
                    key={'alertLoading'}
                  >
                    <Alert severity="info">{`${t(
                      'validation.loadingApi',
                    )}`}</Alert>
                  </Snackbar>
                  <DialogConfirmationModal
                    open={openCreateDialog}
                    setOpen={setOpenCreateDialog}
                    contentText={`${t('validation.confirmation', {
                      action: t('actions.create.lowercased'),
                      item: ' routes',
                    })}`}
                    doAction={() => {
                      if (typeof formRef.current.requestSubmit === 'function') {
                        formRef.current.requestSubmit();
                      } else {
                        formRef.current.dispatchEvent(
                          new Event('submit', { cancelable: true }),
                        );
                      }
                    }}
                    cancelAction={() => {
                      //formRef.setSubmitting(false);
                      setIsSubmitConfirmation(false);
                      setOpenCreateDialog(false);
                    }}
                    buttonText={t('button.continue')}
                  />
                </div>
                <Paper className={classes.formContent}>
                  <Grid container alignContent="flex-start">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={selectedValue}
                      onChange={(event) => {
                        setSelectedValue(event.target.value);
                        setFieldValue('dateType', event.target.value);
                        if (event.target.value === 'dr') {
                          setFieldValue('selectedCalDates', []);
                        } else if (event.target.value === 'cd') {
                          setFieldValue('startDate', '');
                          setFieldValue('endDate', '');
                          setFieldValue('startExceptionDate', '');
                          setFieldValue('endExceptionDate', '');
                        }
                      }}
                    >
                      <FormControlLabel
                        value="dr"
                        control={
                          <Radio
                            value="dr"
                            name="radio1"
                            size="small"
                            sx={{
                              '&.Mui-checked': {
                                color: '#69bc48',
                              },
                            }}
                          />
                        }
                        label={`Select Date range & Days`}
                      />
                      <FormControlLabel
                        value="cd"
                        control={
                          <Radio
                            value="cd"
                            name="radio2"
                            size="small"
                            sx={{
                              '&.Mui-checked': {
                                color: '#69bc48',
                              },
                            }}
                          />
                        }
                        label={`Choose from calendar`}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item>
                    {selectedValue === 'dr' ? (
                      <>
                        <DatesFieldsRHF readOnly={false} />
                        <DaysFieldForPlannedRoutesRHF
                          fieldErr={errors.selectedDays !== undefined}
                        />
                      </>
                    ) : (
                      <Grid item container alignItems="flex-start">
                        <DatePickerMultipleRHF />
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            ) : null}

            <Grid item xs={6}>
              <AccordionContainerRHF
                open={aggreementOpen}
                errors={[errors.transportationDate]}
                setOpen={setAggreementOpen}
                accordionTitle={t('attributeGrouping.agreement')}
              >
                <RouteAgreementFieldsRHF
                  terminal={terminal}
                  trafficReadOnly={terminalORreadonly}
                  classes={classes}
                  template={fromCreateRoute === FROM_CREATE_PLANNED}
                />
              </AccordionContainerRHF>

              <AccordionContainerRHF
                open={noteOpen}
                setOpen={setNoteOpen}
                accordionTitle={t('attributeGrouping.note')}
              >
                <CenteredBox>
                  <CommonTextFieldRHF
                    className={classes.field}
                    name="note"
                    controllerName={'note'}
                    multiline
                    fullWidth
                    rows={4}
                    readOnly={terminalORreadonly}
                  />
                </CenteredBox>
                <CenteredBox>
                  <CommonTextFieldRHF
                    className={classes.field}
                    name="externalNote"
                    controllerName={'externalNote'}
                    multiline
                    fullWidth
                    rows={4}
                    readOnly={terminalORreadonly}
                  />
                </CenteredBox>
              </AccordionContainerRHF>
            </Grid>
            <Grid item xs={6}>
              <AccordionContainerRHF
                open={subcontractorOpen}
                errors={[errors.subcontractorId]}
                setOpen={setSubcontractorOpen}
                accordionTitle={t('resource.subcontractor.capitalized')}
              >
                <SubcontractorFieldsRHF
                  readOnly={readOnly}
                  fromTerminal={terminal}
                />
              </AccordionContainerRHF>
              {includeInvoice && !terminal && (
                <AccordionContainerRHF
                  open={invoiceOpen}
                  setOpen={setInvoiceOpen}
                  accordionTitle={`${t('attributeGrouping.invoice')}`}
                >
                  <>
                    <Typography
                      variant="subtitle1"
                      className={classes.categoryHeader}
                      align="left"
                    >
                      {t('attributeGrouping.invoice')}
                    </Typography>
                    <CommonTextFieldRHF
                      name="invoiceNumber"
                      controllerName={'invoiceNumber'}
                      className={classes.field}
                      readOnly={terminalORreadonly}
                    />
                    <CommonTextFieldRHF
                      name="invoiceNote"
                      controllerName={'invoiceNote'}
                      className={classes.field}
                      readOnly={terminalORreadonly}
                    />
                  </>
                </AccordionContainerRHF>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonWrapper}>
                {!terminal &&
                  !hideCostAllocation &&
                  !(
                    readOnly && getValues('costAllocation')?.items.length === 0
                  ) && (
                    <Tooltip
                      title={`${t('button.view', {
                        item: t('resource.costAllocation.lowercased'),
                      })}`}
                    >
                      <IconButton
                        color={costAllocationOpen ? 'primary' : undefined}
                        onClick={() => {
                          setCostAllocationOpen(!costAllocationOpen);
                        }}
                      >
                        <AttachMoneyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                {!(readOnly && getValues('files').length === 0) && (
                  <Tooltip
                    title={`${t('button.view', {
                      item: t('resource.documentFile.plural'),
                    })}`}
                  >
                    <IconButton
                      color={filesOpen ? 'primary' : undefined}
                      onClick={() => {
                        setFilesOpen(!filesOpen);
                      }}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!(readOnly && getValues('ftl') == null) && (
                  <Tooltip
                    title={`${t('button.view', {
                      item: t('resource.routeFtl.plural'),
                    })}`}
                  >
                    <IconButton
                      color={ftlOpen ? 'primary' : undefined}
                      onClick={() => {
                        if (!Boolean(getValues('ftl'))) {
                          setFieldValue('ftl', createFTL());
                        }
                        setFtlOpen(!ftlOpen);
                      }}
                    >
                      <StorefrontIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  title={`${t('button.view', {
                    item: t('attributes.driverInfo'),
                  })}`}
                >
                  <IconButton
                    color={driverInfoOpen ? 'primary' : undefined}
                    onClick={() => {
                      setDriverInfoOpen(!driverInfoOpen);
                    }}
                  >
                    <DriverInfoCarIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <HideableFormFieldsRHF
                title={`${t('attributes.routeDriverInfo')}`}
                onHideClicked={() => setDriverInfoOpen(false)}
                setOpen={setDriverInfoOpen}
                show={driverInfoOpen}
              >
                <CopyDriverFieldsRouteRHF readOnly={readOnly} />
              </HideableFormFieldsRHF>
            </Grid>
            <Grid item xs={12}>
              <HideableFormFieldsRHF
                setOpen={setFtlOpen}
                show={ftlOpen}
                title={t('resource.routeFtl.capitalized')}
                onHideClicked={() => setFtlOpen(false)}
              >
                <RouteFTLFieldsRHF readOnly={terminalORreadonly} />
              </HideableFormFieldsRHF>
            </Grid>

            <Grid item xs={12}>
              <FilesFieldRHF
                show={filesOpen}
                readOnly={terminalORreadonly}
                setOpen={setFilesOpen}
                onHideClicked={() => setFilesOpen(false)}
              />
            </Grid>
            <Grid item xs={12}>
              <CostAllocationFieldsRHF
                setOpen={setCostAllocationOpen}
                show={!hideCostAllocation && costAllocationOpen}
                onHideClicked={() => setCostAllocationOpen(false)}
                readOnly={readOnly}
              />
            </Grid>

            <AggregatesLoadingListRHF />

            <Grid item xs={12}>
              <Divider />

              <Box mt="5px">
                <LegsFieldsRHF
                  trafficReadOnly={terminalORreadonly}
                  readOnly={readOnly}
                  fromCreateRoute={fromCreateRoute}
                  isFromTerminal={terminal}
                  legIdForLoadingList={legIdForLoadingList}
                  enableEditMode={enableEditMode}
                />
              </Box>
            </Grid>
          </Grid>
          {!readOnly && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                className={classes.button}
                type={'submit'}
                color="primary"
                variant="contained"
                disabled={
                  fromCreateRoute === FROM_CREATE_PLANNED
                    ? alertLoading
                    : isSubmitting
                }
              >
                {submitButtonLabel}
              </Button>
            </Grid>
          )}
        </form>
      </FormProvider>
    </Paper>
  );
}
