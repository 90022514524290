import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { HelmetComponent } from '../components/HelmetComponent';
import {
  RouteFormRHF,
  SporadicRouteFormInput,
} from '../components/RouteFormRHF';
import {
  LoadingListItemInput,
  SporadicRouteInput,
  useCreateSporadicRouteMutation,
  useGetSporadicRouteTemplateLazyQuery,
} from '../generated/graphql';
import { timeOrNull } from '../lib/date_time';
import { newLeg } from '../lib/formHelpers/leg';
import {
  numberOrNull,
  _toNumber,
  _toNumberOrUndefined,
} from '../lib/formHelpers/number';
import { routeValidationSchema } from '../lib/validationSchema/route';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { getCurrencyFromDepartment } from '../utils/GetCurrencyFromDepartment';

export function CreateSporadicRouteRHF() {
  const { template } = useParams<{ template: string }>();
  const router = useHistory();
  const { t } = useTranslation();
  const { department } = useUserConfiguration();

  const [getSporadicRouteTemplate, { data: templateData }] =
    useGetSporadicRouteTemplateLazyQuery();

  const [createSporadicRoute] = useCreateSporadicRouteMutation({});

  useEffect(() => {
    if (template != null) {
      getSporadicRouteTemplate({
        variables: {
          id: parseInt(template, 10),
        },
      });
    }
  }, [template]);

  const onSubmit = async (val: SporadicRouteFormInput) => {
    const values = val as SporadicRouteFormInput;
    let transDateStr = '',
      newRouteId = '';
    if (values.routeId && !values.isCreatedFromTerminal) {
      newRouteId = values.routeId.trim().replaceAll('  ', ' ').toUpperCase();
    }
    if (values.transportationDate) {
      transDateStr = format(new Date(values.transportationDate), 'yyyy-MM-dd');
    }
    const input: SporadicRouteInput = {
      isCreatedFromPlanned: false,
      isCreatedFromTerminal: false,
      transportationDate: transDateStr,
      agreedPrice: values.agreedPrice,
      currency: values.currency,
      note: values.note,
      externalNote: values.externalNote,
      routeDriverName: values.routeDriverName,
      routeDriverPhoneNumber: values.routeDriverPhoneNumber,
      routeCarRegNumber: values.routeCarRegNumber,
      routeTrailerRegNumber: values.routeTrailerRegNumber,
      isCopyDriverPlateInfo: values.isCopyDriverPlateInfo,
      driverName: values.driverName,
      driverPhoneNumber: values.driverPhoneNumber,
      licensePlate: values.licensePlate,
      capacity: numberOrNull(values.capacity),
      kilometer: _toNumberOrUndefined(values.kilometer),
      weight: _toNumberOrUndefined(values.weight),
      routeId: newRouteId,
      dispatcherId: values?.dispatcherId as number,
      subcontractorId: values?.subcontractorId as number,
      ftl:
        values.ftl && values.ftl.customerId && values.ftl.customerNumber
          ? values.ftl
          : undefined,
      files: values.files.map((file) => ({
        id: file.id,
      })),
      legs: values.legs.map((leg, index) => ({
        load: leg.load,
        unload: leg.unload,
        fuel: leg.fuel,
        gateNumber: leg.gateNumber ?? '',
        carRegistrationNumber: leg.carRegistrationNumber ?? '',
        trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
        routeDriverName: leg.routeDriverName ?? '',
        routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
        note: leg.note ?? '',
        arrivalTime: leg.arrivalTime ?? '00:00',
        departureTime: timeOrNull(leg.departureTime),
        actualArrivalDate: leg.transportationDate,
        actualDepartureDate: leg.transportationDate,
        position: index,
        productionDate: leg.productionDate ?? values.transportationDate,
        transportationDate: leg.transportationDate ?? values.transportationDate,
        transportationDateOffset: _toNumber(leg.transportationDateOffset),
        locationId: leg.locationId as number,
        location: undefined,
        loadingListItems: leg.load
          ? leg.loadingListItems.map(
              (loadingListItem): LoadingListItemInput => ({
                locationId: _toNumber(loadingListItem.locationId),
                packages: numberOrNull(loadingListItem.packages),
                pallets: numberOrNull(loadingListItem.pallets),
                palletSpace: numberOrNull(loadingListItem.palletSpace),
                weight: numberOrNull(loadingListItem.weight),
                note: loadingListItem.note,
                checked: loadingListItem.checked,
                isDangerous: loadingListItem.isDangerous,
                classification: loadingListItem.classification,
                unNumber: _toNumberOrUndefined(loadingListItem.unNumber),
                isLimitedQty: loadingListItem.isLimitedQty,
              }),
            )
          : [],
      })),
    };
    await createSporadicRoute({
      variables: {
        input,
      },
    }).then((res) => {
      router.push(
        `/traffic/sporadic-routes${
          res.data?.createSporadicRoute.id
            ? `/${res.data?.createSporadicRoute.id}`
            : ''
        }`,
      );
    });
  };

  const initialValues = (): SporadicRouteFormInput => ({
    isCreatedFromPlanned: false,
    isCreatedFromTerminal: false,
    isCopyDriverPlateInfo: false,
    transportationDate: '',
    agreedPrice: templateData?.sporadicRouteTemplate.agreedPrice,
    currency:
      templateData?.sporadicRouteTemplate.currency ||
      getCurrencyFromDepartment(department?.id),
    note: templateData?.sporadicRouteTemplate.note || '',
    externalNote: templateData?.sporadicRouteTemplate.externalNote || '',
    driverName: templateData?.sporadicRouteTemplate.driverName || '',
    driverPhoneNumber:
      templateData?.sporadicRouteTemplate.driverPhoneNumber || '',
    licensePlate: templateData?.sporadicRouteTemplate.licensePlate || '',
    capacity: templateData?.sporadicRouteTemplate.capacity,
    kilometer: templateData?.sporadicRouteTemplate.kilometer,
    weight: templateData?.sporadicRouteTemplate.weight,
    routeId: templateData?.sporadicRouteTemplate.routeId || '',
    dispatcherId: templateData?.sporadicRouteTemplate.dispatcher?.id,
    subcontractorId: templateData?.sporadicRouteTemplate.subcontractor?.id,
    files: templateData?.sporadicRouteTemplate.files || [],
    invoiceNumber: undefined,
    invoiceNote: undefined,
    totalBringCost: 0,
    newAgreedPrice: 0,
    finalEveryCost: 0,
    finalAdditionalCost: 0,
    ftl: templateData?.sporadicRouteTemplate.ftl
      ? {
          cdc: templateData?.sporadicRouteTemplate.ftl.cdc,
          cmr: templateData?.sporadicRouteTemplate.ftl.cmr,
          price: templateData?.sporadicRouteTemplate.ftl.price,
          currency: templateData?.sporadicRouteTemplate.ftl.currency,
          customerId: templateData?.sporadicRouteTemplate.ftl.customerId,
          customerNumber:
            templateData?.sporadicRouteTemplate.ftl.customerNumber,
          customerReference:
            templateData?.sporadicRouteTemplate.ftl.customerReference,
          invoiceNote: templateData?.sporadicRouteTemplate.ftl.invoiceNote,
        }
      : undefined,
    legs:
      templateData?.sporadicRouteTemplate.legs.map((leg, index) => ({
        key: `${leg.id}`,
        id: leg.id,
        fuel: leg.fuel,
        gateNumber: leg.gateNumber ?? '',
        carRegistrationNumber: leg.carRegistrationNumber ?? '',
        trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
        routeDriverName: templateData?.sporadicRouteTemplate.driverName ?? '',
        routeDriverPhoneNumber:
          templateData?.sporadicRouteTemplate.driverPhoneNumber ?? '',
        load: leg.load,
        unload: leg.unload,
        note: leg.note === '' ? undefined : leg.note,
        arrivalTime: leg.arrivalTime,
        departureTime: timeOrNull(leg.departureTime),
        position: index,
        transportationDate: '',
        transportationDateOffset: leg.transportationDateOffset,
        productionDate: '',
        locationId: leg.locationId,
        location: undefined,
        loadingListItems:
          leg.load && leg.loadingListItems ? leg.loadingListItems : [],
      })) || newLeg(''),
  });

  return (
    <Grid container spacing={1} direction="column">
      <HelmetComponent
        title={`${t('actions.create.capitalized')} ${t(
          'resource.sporadicRoute.capitalized',
        )}`}
      />
      <Grid item>
        <Typography variant="h1">
          {t('resource.sporadicRoute.capitalized')}
        </Typography>
      </Grid>
      <Grid item>
        <RouteFormRHF
          formRef={undefined}
          onSubmit={onSubmit}
          fromCreateRoute={'CreateSporadicRoute'}
          validationSchema={routeValidationSchema(t, false)}
          hideCostAllocation
          initialValues={initialValues()}
          submitButtonLabel={t('button.create', {
            item: t('resource.sporadicRoute.lowercased'),
          })}
        />
      </Grid>
    </Grid>
  );
}
