import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Maybe,
  Reason,
  useGetLocationDeviationsLazyQuery,
} from '../generated/graphql';
import { TABLE_NAMES } from '../lib/constants';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { GridColDef, GridDensityTypes } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface DashReportsProps {
  selectedLocations: number[];
  selectedDate: string;
}

//here terminal represents location, like toTerminalId is toLocationId
interface ReportRemainingGoods {
  id: number;
  customerName: string;
  customerCity: string;
  customerCountryShort: string;
  faultLocationId: number;
  fromTerminalName: string;
  fromTerminalCountry: string;
  toTerminalId: number;
  toTerminalName: string;
  toTerminalCity: string;
  toTerminalCountryShort: string;
  packages: Maybe<number> | undefined;
  pallets: Maybe<number> | undefined;
  palletSpace: Maybe<number> | undefined;
  weight: Maybe<number> | undefined;
  reason: Reason;
  note: string;
  createdBy: string;
  lastUpdatedBy: string;
  isDangerous: boolean;
  otherReason: string;
}

const useStyles = makeStyles(() => ({
  btnSelected: {
    border: '1px solid #68BC46',
    backgroundColor: '#AAE590',
  },
}));

export function TrafficDashRemainingGoods(props: DashReportsProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { selectedLocations, selectedDate } = props;
  const [isCustomerWiseChart, setCustomerWiseChart] = useState<boolean>(false);
  const [isPageLoading, setPageLoading] = useState<boolean>(false);
  const [terminalWiseData, setTerminalWiseData] = useState<
    {
      terminalId: number;
      totalPallets: number;
      totalPalletSpace: number;
      terminalName: string;
    }[]
  >();
  const [customerWiseData, setCustomerWiseData] = useState<
    {
      customerName: string;
      totalPallets: number;
      totalPalletSpace: number;
    }[]
  >();

  const [
    getRemainingGoodsApi,
    { data: remainingGoodsData, loading: remainingGoodsLoading },
  ] = useGetLocationDeviationsLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
  });

  useEffect(() => {
    if (selectedLocations && selectedLocations.length > 0) {
      setPageLoading(true);
      getRemainingGoodsApi({
        variables: {
          startDate: selectedDate,
          endDate: selectedDate,
          terminalIds: selectedLocations,
        },
      });
    } else {
      //no terminal is selected
      setPageLoading(false);
      setCurrentGoodsArray([]);
      setTerminalWiseData(undefined);
      setCustomerWiseData(undefined);
    }
  }, [selectedDate, selectedLocations]);

  const [currentGoodsArray, setCurrentGoodsArray] = useState<
    ReportRemainingGoods[]
  >([]);

  useEffect(() => {
    if (!remainingGoodsLoading) {
      setPageLoading(false);
    }
    if (
      remainingGoodsData &&
      remainingGoodsData.getLocationDeviations &&
      remainingGoodsData.getLocationDeviations.length > 0 &&
      !remainingGoodsLoading
    ) {
      //TEMP filter - need to get filtered from API
      const onlyCustomerLevelArr =
        remainingGoodsData.getLocationDeviations.filter(
          (d) => d.type !== 'Route',
        );
      const sortedArr = _.orderBy(onlyCustomerLevelArr, 'deviationId', 'asc');

      //customer wise row
      const newArrCustomerRowWise: ReportRemainingGoods[] = [];
      if (sortedArr) {
        let cnt = 0;
        for (const rem of sortedArr) {
          const fromLocationDetailsArr = rem.faultLocation?.split('|');
          const fromTerminalCountry = fromLocationDetailsArr
            ? fromLocationDetailsArr[4]
            : '';
          const fromTerminalNameArr = fromLocationDetailsArr
            ? fromLocationDetailsArr[0].split('-')
            : [];
          const fromTerminalName = fromTerminalNameArr
            ? fromTerminalNameArr[0]
            : '';
          const toTerminalDetailsArr = rem.toLocation?.split('|');
          const toTerminalCity = toTerminalDetailsArr
            ? toTerminalDetailsArr[3]
            : '';
          const toTerminalCountry = toTerminalDetailsArr
            ? toTerminalDetailsArr[4]
            : '';
          const toTerminalNameArr = toTerminalDetailsArr
            ? toTerminalDetailsArr[0].split('-')
            : [];
          const toTerminalName = toTerminalNameArr ? toTerminalNameArr[0] : '';
          const customerLocationDetailsArr = rem.customerLocation
            ? rem.customerLocation?.split('|')
            : [];
          const customerCity = customerLocationDetailsArr
            ? customerLocationDetailsArr[3]
            : '';
          const customerCountry = customerLocationDetailsArr
            ? customerLocationDetailsArr[4]
            : '';

          //for customerList
          newArrCustomerRowWise.push({
            id: cnt,
            faultLocationId: rem.faultLocationId ? rem.faultLocationId : 0,
            fromTerminalCountry: fromTerminalCountry,
            fromTerminalName: fromTerminalName,
            toTerminalId: rem.toLocationId ? rem.toLocationId : 0,
            toTerminalCity: toTerminalCity,
            toTerminalCountryShort: toTerminalCountry,
            toTerminalName: toTerminalName,
            customerName: rem.customerName,
            customerCity: customerCity,
            customerCountryShort: customerCountry,
            packages: rem.packages ?? undefined,
            pallets: rem.pallets ?? undefined,
            palletSpace: rem.palletSpace ?? undefined,
            weight: rem.weight ?? undefined,
            note: rem.locationItemNote ?? '',
            reason: rem.reason ? rem.reason : Reason.Other,
            createdBy: rem.createdBy,
            lastUpdatedBy: rem.lastUpdatedBy ? rem.lastUpdatedBy : '',
            isDangerous: rem.isDangerous ?? false,
            otherReason: rem.otherReason ?? '',
          });
          cnt += 1;
        }
      }

      setCurrentGoodsArray(newArrCustomerRowWise);
      //set chart data
      if (newArrCustomerRowWise && newArrCustomerRowWise.length > 0) {
        const terminalWiseTotalData = [];
        const groupedByTerminal = _.groupBy(
          newArrCustomerRowWise,
          'faultLocationId',
        );

        if (groupedByTerminal) {
          for (const [key, value] of Object.entries(groupedByTerminal)) {
            const terminalName = value[0].fromTerminalName;
            terminalWiseTotalData.push({
              terminalId: parseInt(key),
              terminalName: terminalName.includes('-')
                ? terminalName.split('-')[0].trim()
                : terminalName,
              totalPallets: _.sumBy(value, 'pallets') ?? 0,
              totalPalletSpace: _.sumBy(value, 'palletSpace') ?? 0,
            });
          }
          setTerminalWiseData(terminalWiseTotalData);
        }
        //for sum city wise
        const minimalDataArr: {
          fromTerminalId: number;
          toTerminalId: number;
          toTerminalCity: string;
          toCustomerName: string;
          pallets: Maybe<number> | undefined;
          palletSpace: Maybe<number> | undefined;
        }[] = [];

        newArrCustomerRowWise.forEach((rg) => {
          minimalDataArr.push({
            fromTerminalId: rg.faultLocationId,
            toTerminalId: rg.toTerminalId,
            toTerminalCity: rg.toTerminalCity,
            toCustomerName: rg.customerName,
            pallets: rg.pallets,
            palletSpace: rg.palletSpace,
          });
        });

        const groupedByCustomerName = _.groupBy(
          minimalDataArr,
          'toCustomerName',
        );
        if (groupedByCustomerName) {
          const customerWiseTotalData: {
            customerName: string;
            totalPallets: number;
            totalPalletSpace: number;
          }[] = [];

          const allValues = Object.values(groupedByCustomerName);
          allValues.forEach((value) => {
            customerWiseTotalData.push({
              customerName: `${value[0].toCustomerName}`,
              totalPallets: _.sumBy(value, 'pallets') ?? 0,
              totalPalletSpace: _.sumBy(value, 'palletSpace') ?? 0,
            });
          });

          setCustomerWiseData(customerWiseTotalData);
        }
      } else {
        setTerminalWiseData(undefined);
        setCustomerWiseData(undefined);
      }
    } else {
      setCurrentGoodsArray([]);
      setTerminalWiseData(undefined);
      setCustomerWiseData(undefined);
    }
  }, [remainingGoodsData, remainingGoodsLoading]);

  const goodsGridColumns: GridColDef[] = [
    {
      field: 'id',
      type: 'string',
      headerName: 'ID',
      minWidth: 60,
      filterable: false,
    },
    {
      field: 'fromTerminalCountry',
      type: 'string',
      headerName: 'Country',
      minWidth: 180,
      valueGetter({ row }) {
        if (row.fromTerminalCountry && row.fromTerminalCountry !== '')
          return `${row.fromTerminalCountry}`;
        else return '';
      },
    },
    {
      field: 'fromTerminalName',
      type: 'string',
      headerName: 'From Terminal',
      minWidth: 180,
      valueGetter({ row }) {
        if (row.fromTerminalName && row.fromTerminalName !== '')
          return `${row.fromTerminalName}`;
        else return '';
      },
    },
    {
      field: 'toTerminalName',
      type: 'string',
      headerName: 'To Terminal',
      minWidth: 180,
      valueGetter({ row }) {
        if (row.toTerminalCountryShort !== '' && row.toTerminalName !== '')
          return `${row.toTerminalName} - ${row.toTerminalCountryShort}`;
        else return '';
      },
    },
    {
      field: 'customerName',
      type: 'string',
      headerName: 'Customer',
      minWidth: 180,
      valueGetter({ row }) {
        if (
          row.customerName &&
          row.customerName !== '' &&
          row.customerName.name !== 'BLANK'
        ) {
          return `${row.customerName}`;
        } else return '';
      },
    },

    {
      field: 'packages',
      type: 'number',
      headerName: 'Packages',
      minWidth: 60,
      filterable: false,
    },
    {
      field: 'pallets',
      type: 'number',
      headerName: 'Pallets',
      minWidth: 60,
      filterable: false,
    },
    {
      field: 'palletSpace',
      type: 'number',
      headerName: 'Pallet space',
      minWidth: 60,
      filterable: false,
    },
    {
      field: 'weight',
      type: 'number',
      headerName: 'Weight',
      minWidth: 60,
      filterable: false,
    },
    {
      field: 'reason',
      headerName: 'reason',
      valueGetter({ row }) {
        if (row.reason && `${row.reason}` !== 'Other') {
          return t(`enums.remainingGoodsReasonClass.${row.reason}`);
        } else {
          return row.otherReason;
        }
      },
    },
    {
      field: 'isDangerous',
      headerName: 'Dangerous Item',
      type: 'boolean',
    },
    {
      field: 'note',
      type: 'string',
      headerName: 'Note',
      minWidth: 100,
    },
    {
      field: 'createdBy',
      type: 'string',
      headerName: t('attributes.createdBy'),
      minWidth: 100,
    },
    {
      field: 'lastUpdatedBy',
      type: 'string',
      headerName: t('attributes.lastUpdatedBy'),
      minWidth: 100,
      valueGetter({ row }) {
        if (row.lastUpdatedBy && row.lastUpdatedBy !== 'Unknown')
          return `${row.lastUpdatedBy}`;
        else return '';
      },
    },
  ];

  if (isPageLoading) {
    return (
      <Grid item xs={9}>
        <Typography variant="subtitle1" align="center">
          <strong>{t('validation.loadingApi')}</strong>
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      {selectedLocations && selectedLocations.length === 0 && (
        <Grid item xs={9}>
          <Typography variant="subtitle1" align="center">
            <strong>{t('validation.noTerminalSelected')}</strong>
          </Typography>
        </Grid>
      )}
      {selectedLocations &&
        selectedLocations.length > 0 &&
        currentGoodsArray &&
        currentGoodsArray.length === 0 && (
          <Grid item xs={9}>
            <Typography variant="subtitle1" align="center">
              <strong>{t('validation.noRemainingGoods')}</strong>
            </Typography>
          </Grid>
        )}
      <Grid item xs={9}>
        {terminalWiseData && terminalWiseData.length > 0 && (
          <>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={isCustomerWiseChart ? customerWiseData : terminalWiseData}
              >
                <XAxis
                  dataKey={
                    isCustomerWiseChart ? 'customerName' : 'terminalName'
                  }
                  fontSize={12}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="totalPallets" fill="#68bc46" barSize={30} />
                <Bar dataKey="totalPalletSpace" fill="#888c87" barSize={30} />
              </BarChart>
            </ResponsiveContainer>

            <div style={{ marginTop: 4 }}>
              <Button
                style={{ marginRight: 4, paddingTop: 0, paddingBottom: 0 }}
                className={
                  isCustomerWiseChart ? classes.btnSelected : undefined
                }
                disabled={!isCustomerWiseChart}
                variant="contained"
                color="primary"
                onClick={() => {
                  setCustomerWiseChart(false);
                }}
              >
                {`${t('attributes.chartTerminalWise')}`}
              </Button>
              <Button
                style={{ paddingTop: 0, paddingBottom: 0 }}
                className={
                  !isCustomerWiseChart ? classes.btnSelected : undefined
                }
                disabled={isCustomerWiseChart}
                variant="contained"
                color="primary"
                onClick={() => {
                  setCustomerWiseChart(true);
                }}
              >
                {`${t('attributes.chartCustomerWise')}`}
              </Button>
            </div>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        {currentGoodsArray && currentGoodsArray.length > 0 && (
          <>
            <Typography variant="subtitle1" align="left">
              <strong>Remaining goods list</strong>
            </Typography>
            <UserConfiguredDataGridPro
              tableName={TABLE_NAMES.RemainingGoodsReport}
              rows={currentGoodsArray || []}
              loading={false}
              disableMultipleSelection
              disableSelectionOnClick
              columns={goodsGridColumns}
              density={GridDensityTypes.Compact}
              autoHeight={true}
            />
          </>
        )}
      </Grid>
    </>
  );
}
