import { Box, Button, Checkbox, Tooltip } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import { ClassNameMap } from '@material-ui/styles';
import { GridApi, GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { EditableCell } from '../components/datagrid/EditableCell';
import { TrueOrFalseCellWithNoClearIcon } from '../components/datagrid/TrueOrFalseCellWithNoClearIcon';
import { formatCurrency, formatPrice, formatTime } from '../lib/format';

interface routeColumnsOptions {
  t: TFunction;
  path: string;
  apiRef: React.MutableRefObject<GridApi>;
  classes: ClassNameMap<'editableCell'>;
  isForDispatched?: boolean;
  updateExtraColumns?: any;
  getAllData?: any;
  startDate?: string;
  endDate?: string;
}
export const routeColumns = (options: routeColumnsOptions): GridColumns => {
  const {
    t,
    path,
    apiRef,
    classes,
    isForDispatched = false,
    updateExtraColumns,
    getAllData,
    startDate,
    endDate,
  } = options;

  const colArr: GridColumns = [
    {
      field: 'id',
      type: 'string',
      headerName: 'Id',
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: 'View Route',
      width: 100,
      minWidth: 100,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.view')}`}>
              <Link
                to={`${path}/${params.id}`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PageviewIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      headerName: 'View Tour',
      field: 'viewTour',
      width: 75,
      type: 'action',
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return params.row.tourId ? (
          <Tooltip title={`${t('actions.view')}`}>
            <Button to={`/traffic/tours/${params.row.tourId}`} component={Link}>
              <PageviewIcon color="action" />
            </Button>
          </Tooltip>
        ) : null;
      },
    },
    {
      field: 'routeId',
      type: 'string',
      headerName: t(`attributes.routeId`),
      minWidth: 100,
    },
    {
      field: 'multipleSporadicRouteId',
      headerName: 'Multiple sporadic route Id',
    },
    {
      field: 'tourName',
      type: 'string',
      headerName: t(`resource.tour.capitalized`),
      minWidth: 100,
    },
    {
      field: 'driverName',
      headerName: t('attributes.driverName'),
      minWidth: 100,
      type: 'string',
      editable: true,
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      field: 'driverPhoneNumber',
      headerName: t('attributes.driverPhoneNumber'),
      type: 'string',
      editable: true,
      minWidth: 100,
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      field: 'licensePlate',
      headerName: t('attributes.licensePlate'),
      type: 'string',
      editable: true,
      minWidth: 100,
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      field: 'transportationDate',
      type: 'date',
      headerName: t(`attributes.transportationDate`),
      minWidth: 100,
    },
    {
      field: 'invoiceNumber',
      type: 'string',
      headerName: t(`attributes.invoiceNumber`),
      editable: true,
      minWidth: 100,
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      field: 'invoiceNote',
      type: 'string',
      headerName: t(`attributes.invoiceNote`),
      editable: true,
      minWidth: 100,
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      field: 'routeGroupTag',
      type: 'string',
      headerName: t(`attributes.routeGroupTag`),
      editable: true,
      minWidth: 100,
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
      valueParser: (value) => {
        return value ? value.toString().replaceAll(' ', '').toUpperCase() : '';
      },
    },
    {
      field: 'agreementNumber',
      type: 'string',
      headerName: t(`attributes.agreementNumber`),
      minWidth: 100,
    },
    {
      field: 'firstLegTerminalName',
      headerName: t('attributes.firstLegTerminalName'),
      minWidth: 100,
    },
    {
      field: 'departureCity',
      headerName: t('attributes.departureCity'),
      minWidth: 100,
    },
    {
      field: 'departureCountry',
      headerName: t('attributes.departureCountry'),
      minWidth: 100,
    },
    {
      field: 'firstLegDate',
      headerName: t('attributes.firstLegDate'),
      minWidth: 100,
    },
    {
      field: 'departureArrivalTime',
      headerName: t('attributes.arrivalTime'),
      minWidth: 100,
      valueFormatter: formatTime,
    },
    {
      field: 'departureDepartureTime',
      headerName: t('attributes.departureTime'),
      minWidth: 100,
      valueFormatter: formatTime,
    },
    {
      field: 'lastLegTerminalName',
      headerName: t('attributes.lastLegTerminalName'),
      minWidth: 100,
    },
    {
      field: 'arrivalCity',
      headerName: t('attributes.arrivalCity'),
      minWidth: 100,
    },
    {
      field: 'arrivalCountry',
      headerName: t('attributes.arrivalCountry'),
      minWidth: 100,
    },
    {
      field: 'fromToToCountry',
      headerName: t('attributes.fromToToCountry'),
      minWidth: 100,
    },
    {
      field: 'lastLegDate',
      headerName: t('attributes.lastLegDate'),
      minWidth: 100,
    },
    {
      field: 'arrivalArrivalTime',
      headerName: t('attributes.arrivalTime'),
      minWidth: 100,
      valueFormatter: formatTime,
    },
    {
      field: 'arrivalDepartureTime',
      headerName: t('attributes.departureTime'),
      minWidth: 100,
      valueFormatter: formatTime,
    },
    {
      field: 'agreedPrice',
      headerName: t('attributes.agreedPrice'),
      minWidth: 100,
      editable: true,
      type: 'number',
      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
      valueParser: (value) => {
        return value ? +value : +0;
      },
    },
    {
      field: 'totalOfEverything',
      headerName: t('attributes.totalOfEverything'),
      minWidth: 100,
      type: 'string',
      valueFormatter: formatPrice,
    },
    {
      field: 'totalNewAgreedPrice',
      headerName: t('attributes.totalNewAgreedPrice'),
      minWidth: 100,
      type: 'string',
      valueFormatter: formatPrice,
    },
    {
      field: 'totalBringCost',
      headerName: t('attributes.totalBringCost'),
      minWidth: 100,
      type: 'string',
      valueFormatter: formatPrice,
    },
    {
      field: 'totalAdditionalCost',
      headerName: t('attributes.totalAdditionalCost'),
      minWidth: 100,
      type: 'string',
      valueFormatter: formatPrice,
    },
    {
      field: 'currency',
      headerName: t('attributes.currency'),
      minWidth: 100,
      type: 'string',
      valueFormatter: formatCurrency,
    },
    {
      field: 'subcontractorName',
      headerName: t('resource.subcontractor.capitalized'),
      minWidth: 100,
    },
    {
      field: 'note',
      headerName: t('attributes.note'),
      minWidth: 100,
      valueFormatter({ value }) {
        if (!value) {
          return '';
        }
        return (value as string).replaceAll('\n', ' ');
      },
    },
    {
      field: 'externalNote',
      headerName: t('attributes.externalNote'),
      minWidth: 100,
      valueFormatter({ value }) {
        if (!value) {
          return '';
        }
        return (value as string).replaceAll('\n', ' ');
      },
    },
    {
      field: 'capacity',
      headerName: t('attributes.capacity'),
      minWidth: 100,
      valueFormatter({ value }) {
        if (value == null) {
          return '';
        }
        return value;
      },
    },
    {
      field: 'dispatcherName',
      headerName: t('attributes.dispatcherId'),
      minWidth: 100,
      type: 'string',
    },
    {
      field: 'fuel',
      headerName: t('attributes.fuel'),
      minWidth: 100,
      type: 'string',
    },
    {
      field: 'ftlCustomerNumber',
      headerName: t('attributes.customerNumber'),
      minWidth: 100,
      type: 'string',
    },
    {
      field: 'ftlPrice',
      headerName: t('attributes.price'),
      minWidth: 100,
      type: 'string',
    },
    {
      field: 'ftlCurrency',
      headerName: t('attributes.currency'),
      minWidth: 100,
      type: 'string',
    },
    {
      field: 'ftlInvoiceNote',
      headerName: t('attributes.invoiceNote'),
      minWidth: 100,
      type: 'string',
    },
    {
      field: 'ftlCmr',
      headerName: t('attributes.cmr'),
      minWidth: 100,
      type: 'string',
    },
    {
      field: 'ftlCdc',
      headerName: t('attributes.cdc'),
      minWidth: 100,
      type: 'string',
    },
    {
      field: 'isCreatedFromTerminal',
      headerName: 'Terminal-Created',
      minWidth: 150,
      type: 'boolean',
      renderCell(params) {
        return <TrueOrFalseCellWithNoClearIcon {...params} />;
      },
    },
    {
      field: 'isCreatedFromPlanned',
      headerName: 'Dynamically-Created',
      minWidth: 150,
      type: 'boolean',
      renderCell(params) {
        return <TrueOrFalseCellWithNoClearIcon {...params} />;
      },
    },
    {
      headerName: t(`attributes.rutekode`),
      field: 'rutekode',
      minWidth: 100,
      editable: true,
      type: 'text',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.turnummer`),
      field: 'turnummer',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.togref`),
      field: 'togref',
      minWidth: 100,
      editable: true,
      type: 'text',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.bpx`),
      field: 'bpx',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.pri03`),
      field: 'pri03',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.pri49`),
      field: 'pri49',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.upri03`),
      field: 'upri03',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.upri49`),
      field: 'upri49',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.parti`),
      field: 'parti',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.brev`),
      field: 'brev',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },
    {
      headerName: t(`attributes.pru`),
      field: 'pru',
      minWidth: 100,
      editable: true,
      type: 'number',

      renderCell(params) {
        return (
          <EditableCell
            apiRef={apiRef}
            className={classes.editableCell}
            {...params}
          />
        );
      },
    },

    {
      headerName: 'tom',
      field: 'tom',
      minWidth: 100,
      type: 'actions',

      renderCell(params) {
        const { tom } = params.row;
        return (
          <Checkbox
            style={{ padding: 0, color: '#ff0202b8' }}
            checked={tom}
            size="small"
            onChange={(_, checked) => {
              updateExtraColumns({
                variables: {
                  id: parseInt(params.row.id, 10),
                  extraColumnData: {
                    ['tom']: checked,
                  },
                },
              }).then(() => {
                getAllData(startDate, endDate);
              });
            }}
          />
        );
      },
    },
  ];

  if (!isForDispatched) {
    colArr.splice(2, 1);
    colArr.splice(4, 1);
    colArr.splice(12, 1);
    colArr.splice(14, 1);
    colArr.splice(16, 1);
    colArr.splice(19, 1);
    colArr.splice(41, 12);
  } else {
    colArr.splice(4, 1);
  }
  return colArr;
};
