import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DangerIcon } from '../../svgIcons/dangericon.svg';
import {
  DangerousGoodsClassification,
  useUpdateLoadingListItemMutation,
} from '../../generated/graphql';
import { SelectEnumFieldSmall } from '../form/SelectEnumFieldSmall';
import { FastTextFieldUnNumber } from '../form/FastTextFieldUnNumber';
import { useStylesForLoadingList } from '../LoadingList';
import { FastCheckbox } from '../form/FastCheckbox';
import { InfoOutlined } from '@material-ui/icons';
import { CommonTextField } from '../form/CommonTextField';
import { FastTextFieldPackage } from '../form/FastTextFieldPackage';
import { FastTextFieldPallets } from '../form/FastTextFieldPallets';
import { FastTextFieldPalletSpace } from '../form/FastTextFieldPalletSpace';
import { FastTextFieldWeight } from '../form/FastTextFieldWeight';
import { FastTextFieldNote } from '../form/FastTextFieldNote';
import { EditLoadingList } from './ShipmentLoadingListGrid';
import { CheckboxForShipment } from '../form/CheckBoxForShipment';
import { useState } from 'react';
import { SimpleMessageDialog } from '../SimpleMessageDialog';
import { CheckboxIconField } from '../form/CheckboxIconField';

interface panelContentProps {
  shipmentList: EditLoadingList;
  shipmentDate: string;
  shipmentId: number;
}

export function DetailPanelContent(props: panelContentProps) {
  const { shipmentList, shipmentDate, shipmentId } = props;
  const classes = useStylesForLoadingList();
  const [updateLoadingListItem] = useUpdateLoadingListItemMutation();
  const { t } = useTranslation();
  const normalGrey = '#6e6e6e';
  const dangerRed = '#ff0000';
  const shipmentDateStr = shipmentDate ?? '2020-01-01';
  const [openLimitedQtyInfoDialog, setOpenLimitedQtyInfoDialog] =
    useState<boolean>(false);
  const [isUpdateInProgress, setUpdateInProgress] = useState(false);
  const todayDate = new Date().setHours(0, 0, 0, 0);
  const shipmentDateDate = new Date(shipmentDateStr).setHours(0, 0, 0, 0);
  const isPastShipmentDate = todayDate > shipmentDateDate;

  return (
    <Stack
      sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
      direction="column"
    >
      <Paper style={{ flex: 1, width: '90%' }}>
        <Formik
          initialValues={{
            isDangerous: shipmentList.isDangerous,
            isCustom: true,
            isTerminal: true,
            classification: shipmentList.classification,
            unNumber: shipmentList.unNumber,
            isLimitedQty: shipmentList.isLimitedQty,
            customVOECId: shipmentList.customVOECId,
            customInvoiceNo: shipmentList.customInvoiceNo,
            customMrnNo: shipmentList.customMrnNo,
            customWaybillNo: shipmentList.customWaybillNo,
            customCmrNo: shipmentList.customCmrNo,
            customNote: shipmentList.customNote,
            connectedNB: shipmentList.connectedNB,
            customCleared: shipmentList.customCleared,
            customClearedForImport: shipmentList.customClearedForImport,
            terminalCleared: shipmentList.terminalCleared,
            terminalNotCleared: shipmentList.terminalNotCleared,
            terminalShipmentNotArrived: shipmentList.terminalShipmentNotArrived,
            terminalDevPackages: shipmentList.terminalDevPackages,
            terminalDevPallets: shipmentList.terminalDevPallets,
            terminalDevPalletSpace: shipmentList.terminalDevPalletSpace,
            terminalDevWeight: shipmentList.terminalDevWeight,
            terminalDevInfo: shipmentList.terminalDevInfo,
          }}
          onSubmit={(value) => {
            setUpdateInProgress(true);
            updateLoadingListItem({
              variables: {
                input: {
                  id: shipmentList.id ? shipmentList.id : 0,
                  classification: value.classification,
                  connectedNB: value.connectedNB,
                  customCleared: value.customCleared,
                  customClearedForImport: value.customClearedForImport,
                  customCmrNo: value.customCmrNo,
                  customInvoiceNo: value.customInvoiceNo,
                  customMrnNo: value.customMrnNo,
                  customNote: value.customNote,
                  customVOECId: value.customVOECId,
                  customWaybillNo: value.customWaybillNo,
                  isDangerous: value.isDangerous,
                  isLimitedQty: value.isLimitedQty,
                  terminalCleared: value.terminalCleared,
                  terminalDevInfo: value.terminalDevInfo,
                  terminalDevPackages: value.terminalDevPackages,
                  terminalDevPallets: value.terminalDevPallets,
                  terminalDevPalletSpace: value.terminalDevPalletSpace,
                  terminalDevWeight: value.terminalDevWeight,
                  terminalNotCleared: value.terminalNotCleared,
                  terminalShipmentNotArrived: value.terminalShipmentNotArrived,
                  unNumber: value.unNumber,
                },
                shipmentId,
              },
            }).then(() => setUpdateInProgress(false));
          }}
        >
          {({ values }) => {
            return (
              <Form>
                <Grid container>
                  <Grid item xs={2}>
                    <Grid item container direction="row">
                      <Typography
                        style={{
                          fontSize: 12,
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                        color="textPrimary"
                      >{` Is Dangerous-  `}</Typography>
                      <Tooltip
                        title={`${t('attributes.tooltipDangerousGoodsIcon')}`}
                      >
                        <div>
                          <CheckboxIconField
                            name={'isDangerous'}
                            icon={
                              <DangerIcon
                                height={24}
                                width={24}
                                fill={normalGrey}
                              />
                            }
                            checkedIcon={
                              <DangerIcon
                                height={24}
                                width={24}
                                fill={dangerRed}
                              />
                            }
                            size="small"
                            readOnly={isPastShipmentDate}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    {values.isDangerous && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{
                          width: '100%',
                        }}
                      >
                        {/* new for dangerous goods info */}
                        <Grid
                          item
                          xs={6}
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Grid item xs={3}>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                              color="textPrimary"
                            >{`Goods Info -`}</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <SelectEnumFieldSmall
                              name={'classification'}
                              enumObject={DangerousGoodsClassification}
                              translationPath={'enums.dangerousGoodsClass'}
                              readOnly={isPastShipmentDate}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Grid item xs={2}>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                              color="textPrimary"
                            >{`UN-`}</Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <FastTextFieldUnNumber
                              noLabel
                              size="medium"
                              name={'unNumber'}
                              className={classes.numberFields}
                              type="number"
                              placeholder="4 digit number"
                              readOnly={isPastShipmentDate}
                              smallPadding
                              autoComplete="off"
                              onInput={(e) => {
                                (e.target as HTMLInputElement).value = Math.max(
                                  0,
                                  parseInt(
                                    (e.target as HTMLInputElement).value,
                                  ),
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={2} container direction="row">
                          <Grid item xs={10}>
                            <FastCheckbox
                              name={'isLimitedQty'}
                              label={t('attributes.limitedQty')}
                              size="small"
                              readOnly={isPastShipmentDate}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={`${t('attributes.tooltipLimitedQtyInfo')}`}
                            >
                              <IconButton
                                className={classes.deleteButton}
                                onClick={() =>
                                  setOpenLimitedQtyInfoDialog(true)
                                }
                              >
                                <InfoOutlined />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <SimpleMessageDialog
                          open={openLimitedQtyInfoDialog}
                          setOpen={setOpenLimitedQtyInfoDialog}
                          title={t('validation.limitedQtyInfoTitle')}
                          contentText={t('validation.limitedQtyInfo')}
                          isMultiLine={true}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Divider
                  color="green"
                  variant="fullWidth"
                  style={{ margin: 10 }}
                />
                {values.isCustom && (
                  <>
                    <Grid container>
                      <Grid container item xs={3}>
                        <Grid item xs={3} direction="row" alignItems="center">
                          <Typography
                            style={{
                              fontSize: 12,
                              marginTop: 3,
                            }}
                            color="textPrimary"
                          >{`VOEC ID:`}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <CommonTextField
                            name={'customVOECId'}
                            size="small"
                            label={false}
                            className={classes.field}
                            smallPadding
                            readOnly={isPastShipmentDate}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item xs={3}>
                        <Grid item xs={2} direction="row" alignItems="center">
                          <Typography
                            style={{
                              fontSize: 12,
                              marginTop: 3,
                            }}
                            color="textPrimary"
                          >{`Invoice:`}</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <CommonTextField
                            name={'customInvoiceNo'}
                            size="small"
                            label={false}
                            className={classes.field}
                            smallPadding
                            readOnly={isPastShipmentDate}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={3}>
                        <Grid
                          item
                          xs={3}
                          direction="row"
                          alignItems="flex-start"
                        >
                          <Typography
                            style={{
                              fontSize: 12,
                              marginTop: 3,
                            }}
                            color="textPrimary"
                          >{`MRN No:`}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <CommonTextField
                            name={'customMrnNo'}
                            size="small"
                            label={false}
                            className={classes.field}
                            smallPadding
                            readOnly={isPastShipmentDate}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={3}>
                        <Grid item xs={2} direction="row" alignItems="center">
                          <Typography
                            style={{
                              fontSize: 12,
                              marginTop: 3,
                            }}
                            color="textPrimary"
                          >{`Waybill:`}</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <CommonTextField
                            name={'customWaybillNo'}
                            size="small"
                            label={false}
                            className={classes.field}
                            smallPadding
                            readOnly={isPastShipmentDate}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: 5 }}>
                      <Grid item container xs={3}>
                        <Grid item xs={3}>
                          <Typography
                            style={{
                              fontSize: 12,
                              marginTop: 3,
                            }}
                            color="textPrimary"
                          >{`CMR No:`}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <CommonTextField
                            name={'customCmrNo'}
                            size="small"
                            label={false}
                            className={classes.field}
                            smallPadding
                            readOnly={isPastShipmentDate}
                          />
                        </Grid>
                      </Grid>

                      <Grid item container xs={3}>
                        <Grid item xs={2}>
                          <Typography
                            style={{
                              fontSize: 12,
                              marginTop: 3,
                            }}
                            color="textPrimary"
                          >{`Note:`}</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <CommonTextField
                            name={'customNote'}
                            size="small"
                            label={false}
                            className={classes.field}
                            smallPadding
                            readOnly={isPastShipmentDate}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={3}>
                        <Grid item xs={4}>
                          <Typography
                            style={{
                              fontSize: 12,
                              marginTop: 3,
                            }}
                            color="textPrimary"
                          >{`Connected No:`}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <CommonTextField
                            name={'connectedNB'}
                            size="small"
                            label={false}
                            className={classes.field}
                            smallPadding
                            readOnly={isPastShipmentDate}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={3}>
                        <Grid item xs={12}>
                          <FastCheckbox
                            name={'customCleared'}
                            label={t('attributes.clearedFromCustom')}
                            size="small"
                            readOnly={isPastShipmentDate}
                          />
                          <FastCheckbox
                            name={'customClearedForImport'}
                            label={t('attributes.clearedFromCustomImport')}
                            size="small"
                            readOnly={isPastShipmentDate}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Divider
                  color="green"
                  variant="fullWidth"
                  style={{ margin: 10 }}
                />
                {values.isTerminal && (
                  <>
                    <Grid
                      container
                      alignItems="flex-start"
                      alignContent="flex-start"
                    >
                      <Grid item xs={3}>
                        <CheckboxForShipment
                          name={'terminalCleared'}
                          label={t('attributes.terminalCleared')}
                          size="small"
                          readOnly={isPastShipmentDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CheckboxForShipment
                          name={'terminalNotCleared'}
                          label={t('attributes.terminalNotCleared')}
                          size="small"
                          readOnly={isPastShipmentDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CheckboxForShipment
                          name={'terminalShipmentNotArrived'}
                          label={t('attributes.shipmentNotArrived')}
                          size="small"
                          readOnly={isPastShipmentDate}
                        />
                      </Grid>
                      {values.terminalNotCleared && (
                        <Grid
                          xs={12}
                          container
                          direction="row"
                          alignItems="center"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item xs={1}>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                              color="textPrimary"
                            >{`Packages`}</Typography>
                            <FastTextFieldPackage
                              noLabel
                              size="small"
                              smallFontSize
                              name={'terminalDevPackages'}
                              className={classes.numberFields}
                              type="number"
                              smallPadding
                              readOnly={isPastShipmentDate}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                              color="textPrimary"
                            >{`Pallets`}</Typography>
                            <FastTextFieldPallets
                              noLabel
                              size="small"
                              smallFontSize
                              name={'terminalDevPallets'}
                              className={classes.numberFields}
                              type="number"
                              smallPadding
                              readOnly={isPastShipmentDate}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                              color="textPrimary"
                            >{`PalletPlace`}</Typography>
                            <FastTextFieldPalletSpace
                              noLabel
                              size="small"
                              smallFontSize
                              smallPadding
                              name={'terminalDevPalletSpace'}
                              className={classes.numberFields}
                              type="number"
                              readOnly={isPastShipmentDate}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                              color="textPrimary"
                            >{`Weight`}</Typography>
                            <FastTextFieldWeight
                              noLabel
                              size="small"
                              smallFontSize
                              smallPadding
                              name={'terminalDevWeight'}
                              className={classes.numberFields}
                              type="number"
                              readOnly={isPastShipmentDate}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{
                              marginRight: 2,
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                              color="textPrimary"
                            >{`Information`}</Typography>
                            <FastTextFieldNote
                              noLabel
                              size="small"
                              multiline
                              smallFontSize
                              name={'terminalDevInfo'}
                              className={classes.field}
                              smallPadding
                              readOnly={isPastShipmentDate}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
                <Divider
                  color="green"
                  variant="fullWidth"
                  style={{ margin: 10 }}
                />
                <Grid
                  item
                  container
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  style={{ marginBottom: 5 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isUpdateInProgress || isPastShipmentDate}
                    size="small"
                  >
                    {`${
                      isUpdateInProgress
                        ? t('validation.loadingApi')
                        : t('actions.update')
                    }`}
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </Stack>
  );
}
