import { TFunction } from 'i18next';
import * as yup from 'yup';
import { DangerousGoodsClassification } from '../../generated/graphql';

export const loadingListItemYupValidation = (t: TFunction) =>
  yup.array(
    yup.object().shape({
      packages: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .integer(
          t('validation.noDecimals', {
            name: t('attributes.packages'),
            len: '0',
          }),
        )
        .nullable()
        .min(
          0,
          t('validation.minNumber', {
            name: t('attributes.packages'),
            len: '0',
          }),
        ),
      pallets: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .integer(
          t('validation.noDecimals', {
            name: t('attributes.agreedPrice'),
            len: '0',
          }),
        )
        .nullable()
        .min(
          0,
          t('validation.minNumber', {
            name: t('attributes.agreedPrice'),
            len: '0',
          }),
        ),
      palletSpace: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .min(
          0,
          t('validation.minNumber', {
            name: t('attributes.palletSpace'),
            len: '0',
          }),
        ),
      weight: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .min(
          0,
          t('validation.minNumber', {
            name: t('attributes.weight'),
            len: '0',
          }),
        ),
      note: yup.string(),
      locationId: yup.string().required(
        t('validation.isRequired', {
          name: t('attributes.customer'),
        }),
      ),
      isDangerous: yup.boolean(),
      classification: yup.string().when('isDangerous', {
        is: true,
        then: yup
          .string()
          .required()
          .oneOf(Object.keys(DangerousGoodsClassification)),
        otherwise: yup.string().nullable(),
      }),
      unNumber: yup.string().when('isDangerous', {
        is: true,
        then: yup
          .string()
          .required(t('validation.unNumber'))
          .matches(/^[0-9]{4}$/, t('validation.unNumberLength')),
        otherwise: yup.string().nullable(),
      }),
    }),
  );

export const terminalLoadingListItemYupValidation = (t: TFunction) =>
  yup.array(
    yup.object().shape(
      {
        packages: yup
          .number()
          .integer(
            t('validation.noDecimals', {
              name: t('attributes.packages'),
              len: '0',
            }),
          )
          .nullable()
          .min(
            0,
            t('validation.minNumber', {
              name: t('attributes.packages'),
              len: '0',
            }),
          ),
        pallets: yup
          .number()
          .integer(
            t('validation.noDecimals', {
              name: t('attributes.pallets'),
              len: '0',
            }),
          )
          .nullable()
          .positive(
            t('validation.fieldPositive', {
              name: t('attributes.pallets'),
            }),
          )
          .min(
            0,
            t('validation.minNumber', {
              name: t('attributes.pallets'),
              len: '0',
            }),
          ),
        palletSpace: yup
          .number()
          .integer(
            t('validation.noDecimals', {
              name: t('attributes.palletSpace'),
              len: '0',
            }),
          )
          .nullable()
          .positive(
            t('validation.fieldPositive', {
              name: t('attributes.palletSpace'),
            }),
          ),
        weight: yup
          .number()
          .nullable()
          .min(
            0,
            t('validation.minNumber', {
              name: t('attributes.weight'),
              len: '0',
            }),
          ),
        note: yup.string(),
        locationId: yup
          .string()
          .transform((value) =>
            Number.isNaN(value) || value == 0 ? null : value,
          )
          .typeError(
            t('validation.isRequired', {
              name: t('attributes.customer'),
            }),
          )
          .required(
            t('validation.isRequired', {
              name: t('attributes.customer'),
            }),
          ),
        isDangerous: yup.boolean(),
        classification: yup.string().when('isDangerous', {
          is: true,
          then: yup
            .string()
            .required()
            .oneOf(Object.keys(DangerousGoodsClassification)),
          otherwise: yup.string().nullable(),
        }),
        unNumber: yup.string().when('isDangerous', {
          is: true,
          then: yup
            .string()
            .required(t('validation.unNumber'))
            .matches(/^[0-9]{4}$/, t('validation.unNumberLength')),
          otherwise: yup.string().nullable(),
        }),
      },
      [['pallets', 'palletSpace']],
    ),
  );
