import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { format, parse } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetComponent } from '../components/HelmetComponent';
import { useGetInternalTerminalsQuery } from '../generated/graphql';
import { COUNTRIES_MAP } from '../lib/constants';
import { DATE_FORMAT } from '../lib/date_time';
import { getUserName } from '../lib/useRoles';
import { LoadingAndError } from '../utils/LoadingAndError';
import _ from 'lodash';
import { TrafficDashRemainingGoods } from './TrafficDashRemainingGoods';
import { TrafficDashFilledItems } from './TrafficDashFilledItems';
import { TrafficDashBoxAndInfo } from './TrafficDashBoxAndInfo';
import IconRemainingGoods from '../images/ic_warehouse.png';
import IconFilledFactors from '../images/ic_analysis.png';
import IconInformation from '../images/ic_information.png';
import { SelectDate } from '../components/SelectDate';
import { getCurrentDate, getTFTMasterDate } from '../lib/date';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { styleForCountryModal } from './TrafficTFT';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 20,
    height: 30,
    maxHeight: 30,
    backgroundColor: '#dedede',
    '&.Mui-expanded': {
      minHeight: 20,
      height: 30,
      maxHeight: 30,
      backgroundColor: '#dedede',
    },
  },
})(AccordionSummary);

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    marginLeft: 4,
    marginTop: 20,
    border: '2px solid #e8e8e8',
    borderRadius: 2,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  displayTerminals: {
    marginLeft: 4,
    marginTop: 5,
    border: '2px solid #e8e8e8',
    borderRadius: 2,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  gridItemStyle: { padding: '1%' },
  componentTitle: {
    wordWrap: 'normal',
  },
  selectedType: {
    border: '2px solid green',
    padding: 4,
    height: 95,
    width: 70,
    backgroundColor: '#c9f2b8',
  },
  unselectedType: {
    padding: 2,
    height: 95,
    width: 70,
    cursor: 'pointer',
  },
}));

export type DataTerminal = {
  terminalId: number;
  shortCountryCode: string;
  name?: string;
  contacts?: {
    email: string;
    tags: {
      title: string;
    }[];
  }[];
};

interface ProductionReportProps {
  isInTerminal: boolean;
  terminal: {
    id: number;
    terminal: {
      locations: {
        id: number;
        country: string;
      }[];
    };
    name?: string;
  };
}

export function TrafficDashboard(props: ProductionReportProps) {
  const classes = useStyles();
  const { isInTerminal, terminal } = props;
  const { getCustomConfiguration, updateCustomConfiguration } =
    useUserConfiguration();

  const { t } = useTranslation();
  const [user] = useState<string>(getUserName());
  const REPORT_REMAINING_GOODS = 'rg';
  const REPORT_FILLED_FACTOR = 'ff';
  const REPORT_INFORMATION = 'ri';
  const [displayTerminals, setDisplayTerminals] = useState<boolean>(false);
  const [selectedTerminals, setSelectedTerminals] = useState<DataTerminal[]>(
    [],
  );
  const [selectedLocations, setSelectedLocations] = useState<number[]>([]);
  const [selectedReport, setSelectedReport] = useState<string>(
    REPORT_REMAINING_GOODS,
  );

  const [expanded, setExpanded] = useState<string | undefined>(
    localStorage.getItem('reportToTerminalExpanded_' + user) ?? undefined,
  );
  const history = useHistory();
  const NavigateTo = () => history.push('traffic/tft');

  async function getconfigTerminal() {
    const getSelectedTerminal = await getCustomConfiguration(
      'reportDashboard',
      'userSelection',
    );
    return getSelectedTerminal;
  }

  const handleUpdateTerminal = (terminalobj: any[], location: any[]) => {
    const isInTerminalList = (obj: any) =>
      selectedTerminals.some((item) => item.terminalId === obj.terminalId);
    const uniqueTerminals = terminalobj.filter(
      (terminalobj, index, self) =>
        index ===
        self.findIndex((t) => t.terminalId === terminalobj.terminalId),
    );

    const isInLocation = (obj: any) =>
      selectedLocations.some((item) => item === obj);
    const uniqueLocations = location.filter(
      (locationId, index, self) =>
        index === self.findIndex((t) => t === locationId),
    );

    function getLocationList() {
      let l: number[] = [];
      location?.forEach((location: number) => {
        if (isInLocation(location)) {
          const locationToUnselect = selectedLocations.filter(
            (locationId: number) => locationId === location,
          );
          const locationListAfterUnselect = selectedLocations?.filter(
            (locationData) => locationData != locationToUnselect[0],
          );

          l = [...locationListAfterUnselect];
        } else {
          const finallocationList = [...uniqueLocations, ...selectedLocations];
          l = [...finallocationList];
        }
      });
      return l;
    }

    function getfinalTerminalList() {
      let t: DataTerminal[] = [];
      terminalobj?.forEach((term: DataTerminal) => {
        if (isInTerminalList(term)) {
          const terminalToUnselect = selectedTerminals.filter(
            (obj) => obj.terminalId === term.terminalId,
          );
          const terminalListAfterUnselect = selectedTerminals.filter(
            (d) => d.terminalId != terminalToUnselect[0].terminalId,
          );
          t = [...terminalListAfterUnselect];
        } else {
          const finalTerminalList = [...uniqueTerminals, ...selectedTerminals];
          t = [...finalTerminalList];
        }
      });
      return t;
    }
    const userSelection = {
      finalselectedTerminals: getfinalTerminalList(),
      finalselectedLocations: getLocationList(),
    };

    updateCustomConfiguration(
      'reportDashboard',
      'userSelection',
      userSelection,
    );
  };

  useEffect(() => {
    getconfigTerminal()
      .then((data: any) => {
        const terminalDataList = data?.finalselectedTerminals;
        const locationDataList = data?.finalselectedLocations;

        if (terminalDataList.length === 1 && terminalDataList[0]) {
          const terminalobj = {
            terminalId: terminalDataList[0]?.terminalId,
            shortCountryCode: terminalDataList[0]?.shortCountryCode,
            name: terminalDataList[0]?.name,
          };

          setSelectedTerminals(
            isInTerminal
              ? [
                  {
                    terminalId: terminal.id,
                    shortCountryCode: terminal.terminal.locations[0].country,
                    name: terminal.name ? terminal.name : '',
                  },
                ]
              : [terminalobj],
          );
          setSelectedLocations([locationDataList[0]]);
        } else {
          setSelectedTerminals([]);
          setSelectedLocations([]);
        }
      })
      .catch(() => {
        setSelectedTerminals([]);
        setSelectedLocations([]);
      });

    if (isInTerminal) setExpanded(terminal.terminal.locations[0].country);
  }, []);

  const handleChange =
    (country: string) =>
    (_event: React.SyntheticEvent, isExpanded: boolean) => {
      localStorage.setItem('reportToTerminalExpanded_' + user, country);
      setExpanded(isExpanded ? country : undefined);
    };
  const selDate = isInTerminal ? getTFTMasterDate(true) : getCurrentDate();
  const [startDate, setStartDate] = useState<{ date: string; day: string }>({
    date: format(selDate, DATE_FORMAT),
    day: selDate.toLocaleDateString(undefined, {
      weekday: 'long',
    }),
  });

  const { data, loading, error } = useGetInternalTerminalsQuery({
    variables: {
      fetchFromAllDepartments: true,
    },
    fetchPolicy: 'cache-and-network',
  });
  // internalTerminals returns only four properties, adding one more for locationId
  const internalTerminals = (data?.internalTerminals || [])
    .filter(
      (item) =>
        typeof item !== 'undefined' && item.id && item.terminal?.locations[0],
    )
    .map((x) => {
      const location = x.terminal.locations[0];
      const locationId = x.terminal.locations[0].id;
      const locationString =
        location != null
          ? `${location.address}, ${location.city}, ${location.countryLong}`
          : '';
      const terminalLocationCountry: string = x.terminal.locations[0]?.country;

      return {
        id: x.id,
        name: x.terminal.name,
        location: locationString,
        terminalLocationCountry: terminalLocationCountry,
        locationId: locationId,
      };
    });
  const countryList: string[] = internalTerminals?.length
    ? Array.from(
        new Set(
          internalTerminals.flatMap((item) => item.terminalLocationCountry),
        ),
      )
    : [];

  const getFullReportName = (shortName: string) => {
    switch (shortName) {
      case REPORT_REMAINING_GOODS:
        return 'Remaining Goods';
      case REPORT_FILLED_FACTOR:
        return 'Filled Factors and PPLs';
      case REPORT_INFORMATION:
        return 'Information';
    }
  };

  const selectAllForCountry = (countryCode: string) => {
    const allTerminalsId: DataTerminal[] = [];
    const allLocationsId: number[] = [];

    if (internalTerminals && internalTerminals.length > 0) {
      internalTerminals
        .filter((it) => it.terminalLocationCountry === countryCode)
        .forEach((it) => {
          //passing locationId
          allTerminalsId.push({
            terminalId: it.id,
            shortCountryCode: it.terminalLocationCountry,
            name: it.name,
          });
          allLocationsId.push(it.locationId);
        });
      //set to selectedTerminals state
      if (selectedTerminals && selectedTerminals.length > 0) {
        const combinedArr = _.union(selectedTerminals, allTerminalsId);
        setSelectedTerminals(Array.from(new Set(combinedArr)));
        setSelectedLocations(Array.from(new Set(allLocationsId)));
      } else {
        setSelectedTerminals(allTerminalsId);
        setSelectedLocations(allLocationsId);
      }
    }
  };

  return (
    <>
      <Modal
        open={displayTerminals}
        onClose={() => setDisplayTerminals(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <Box sx={styleForCountryModal}>
          <IconButton
            style={{ marginLeft: 550 }}
            onClick={() => setDisplayTerminals(false)}
            edge="start"
          >
            <CloseIcon fontSize="large" color="action" />
          </IconButton>
          <LoadingAndError
            error={error}
            loading={loading}
            data={internalTerminals}
          >
            {({ loadedData }) => (
              <>
                {countryList.flatMap((ctryItem) => (
                  <Accordion
                    expanded={expanded === ctryItem}
                    onChange={handleChange(ctryItem)}
                  >
                    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle1">
                        <strong>
                          {
                            COUNTRIES_MAP[
                              ctryItem as keyof typeof COUNTRIES_MAP
                            ]
                          }
                        </strong>
                      </Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                      {selectedReport === REPORT_REMAINING_GOODS && (
                        <Button
                          variant="outlined"
                          color="default"
                          size="small"
                          onClick={() => {
                            selectAllForCountry(ctryItem);
                          }}
                        >
                          {`-Select All from ${
                            COUNTRIES_MAP[
                              ctryItem as keyof typeof COUNTRIES_MAP
                            ]
                          }-`}
                        </Button>
                      )}
                      <List dense={true}>
                        {loadedData
                          .filter((l) => l.terminalLocationCountry === ctryItem)
                          .map((item) => (
                            <>
                              <ListItem key={item.id} divider>
                                <Typography
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    if (selectedTerminals.length > 0) {
                                      if (
                                        selectedTerminals.findIndex(
                                          (i) => i.terminalId === item.id,
                                        ) > -1
                                      ) {
                                        setSelectedTerminals(
                                          selectedTerminals.filter(
                                            (i) => i.terminalId !== item.id,
                                          ),
                                        );
                                        setSelectedLocations(
                                          selectedLocations.filter(
                                            (i) => i !== item.locationId,
                                          ),
                                        );
                                      } else if (
                                        selectedReport ===
                                        REPORT_REMAINING_GOODS
                                      ) {
                                        setSelectedTerminals([
                                          ...selectedTerminals,
                                          {
                                            terminalId: item.id,
                                            shortCountryCode:
                                              item.terminalLocationCountry,
                                            name: item.name,
                                          },
                                        ]);
                                        setSelectedLocations([
                                          ...selectedLocations,
                                          item.locationId,
                                        ]);
                                      } else {
                                        setSelectedTerminals([
                                          {
                                            terminalId: item.id,
                                            shortCountryCode:
                                              item.terminalLocationCountry,
                                            name: item.name,
                                          },
                                        ]);
                                      }
                                    } else {
                                      setSelectedTerminals([
                                        ...selectedTerminals,
                                        {
                                          terminalId: item.id,
                                          shortCountryCode:
                                            item.terminalLocationCountry,
                                          name: item.name,
                                        },
                                      ]);
                                      setSelectedLocations([
                                        ...selectedLocations,
                                        item.locationId,
                                      ]);
                                    }
                                    handleUpdateTerminal(
                                      [
                                        {
                                          terminalId: item.id,
                                          shortCountryCode:
                                            item.terminalLocationCountry,
                                          name: item.name,
                                        },
                                      ],
                                      [item.locationId],
                                    );
                                  }}
                                  variant="body2"
                                  color={
                                    selectedTerminals &&
                                    selectedTerminals.findIndex(
                                      (t) => t.terminalId === item.id,
                                    ) >= 0
                                      ? 'primary'
                                      : 'inherit'
                                  }
                                >
                                  {item.name}
                                </Typography>
                              </ListItem>
                            </>
                          ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            )}
          </LoadingAndError>
        </Box>
      </Modal>

      <HelmetComponent title={t('resource.reportToTerminal.capitalized')} />

      <Grid container>
        <Grid item xs={3}>
          {/* Icon box */}
          <Typography
            style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 4 }}
            align="left"
          >{`${t('attributes.prodReportTitle')} - ${getFullReportName(
            selectedReport,
          )}`}</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{
              marginTop: 4,
              marginBottom: 10,
              paddingLeft: 6,
              paddingRight: 6,
              backgroundColor: '#e8e8e8',
            }}
          >
            <div
              onClick={() => setSelectedReport(REPORT_REMAINING_GOODS)}
              className={
                selectedReport === REPORT_REMAINING_GOODS
                  ? classes.selectedType
                  : classes.unselectedType
              }
            >
              <img height={'44'} width={'44'} src={IconRemainingGoods} />
              <Typography style={{ fontSize: 11 }}>
                {`${t('attributes.reportRemainingGoods')}`}
              </Typography>
            </div>
            <div
              onClick={() => {
                setSelectedReport(REPORT_FILLED_FACTOR);
                if (selectedTerminals.length === 1) {
                  setSelectedTerminals([
                    {
                      terminalId: selectedTerminals[0]?.terminalId ?? 0,
                      shortCountryCode:
                        selectedTerminals[0]?.shortCountryCode ?? '',
                      name: selectedTerminals[0]?.name ?? '',
                    },
                  ]);
                  setSelectedLocations([selectedLocations[0]]);
                } else if (selectedTerminals.length > 1) {
                  setSelectedTerminals([]);
                  setSelectedLocations([]);
                }
              }}
              className={
                selectedReport === REPORT_FILLED_FACTOR
                  ? classes.selectedType
                  : classes.unselectedType
              }
            >
              <img height={'44'} width={'44'} src={IconFilledFactors} />
              <Typography style={{ fontSize: 11 }}>
                {`${t('attributes.reportFilledFactors')}`}
              </Typography>
            </div>
            <div
              onClick={() => {
                setSelectedReport(REPORT_INFORMATION);
                if (selectedTerminals.length === 1) {
                  setSelectedTerminals([
                    {
                      terminalId: selectedTerminals[0]?.terminalId ?? 0,
                      shortCountryCode:
                        selectedTerminals[0]?.shortCountryCode ?? '',
                      name: selectedTerminals[0]?.name ?? '',
                    },
                  ]);
                  setSelectedLocations([selectedLocations[0]]);
                } else if (selectedTerminals.length > 1) {
                  setSelectedTerminals([]);
                  setSelectedLocations([]);
                }
              }}
              className={
                selectedReport === REPORT_INFORMATION
                  ? classes.selectedType
                  : classes.unselectedType
              }
            >
              <img height={'44'} width={'44'} src={IconInformation} />
              <Typography style={{ fontSize: 11 }}>
                {`${t('attributes.reportInformation')}`}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item container>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={NavigateTo}
              >
                {t('button.tftOverview')}
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setDisplayTerminals(!displayTerminals);
                }}
              >
                {t('attributes.selectTerminals')}
              </Button>

              {selectedReport !== REPORT_FILLED_FACTOR ? (
                <>
                  <SelectDate
                    currentDate={startDate.date}
                    onDateSelected={(selDate) => {
                      if (selDate) {
                        setStartDate({
                          date: selDate,
                          day: parse(
                            selDate,
                            DATE_FORMAT,
                            new Date(),
                          ).toLocaleDateString(undefined, {
                            weekday: 'long',
                          }),
                        });
                      }
                    }}
                  />
                  <Typography variant="body1">{startDate.day}</Typography>
                </>
              ) : null}
            </div>
          </Grid>
          <Grid item container>
            {selectedTerminals.length > 0 ? (
              <div className={classes.displayTerminals}>
                <Typography variant="subtitle1" align="center">
                  <strong>{`${t('validation.selectedTerminals')}`}</strong>
                </Typography>

                {selectedTerminals.map((terminal) => (
                  <Typography variant="subtitle1" align="center">
                    {`${terminal.name},`}
                  </Typography>
                ))}
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Grid item container>
        {selectedReport === REPORT_REMAINING_GOODS ? (
          <TrafficDashRemainingGoods
            selectedLocations={selectedLocations}
            selectedDate={startDate.date}
          />
        ) : selectedReport === REPORT_INFORMATION ? (
          <TrafficDashBoxAndInfo
            selectedTerminalId={
              selectedTerminals.length > 0 ? selectedTerminals[0].terminalId : 0
            }
            selectedDate={startDate.date}
          />
        ) : (
          <TrafficDashFilledItems
            isInTerminal={isInTerminal}
            selectedTerminalId={
              selectedTerminals.length > 0 ? selectedTerminals[0].terminalId : 0
            }
            selectedCountryCode={
              selectedTerminals.length > 0
                ? selectedTerminals[0].shortCountryCode
                : ''
            }
          />
        )}
      </Grid>
    </>
  );
}
