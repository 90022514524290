import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { format, parse } from 'date-fns';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FuelType, Maybe } from '../../generated/graphql';
import { DATE_FORMAT } from '../../lib/date_time';
import { LoadingList, LoadingListItemInterface } from '../LoadingList';
import { TransportationDateOffsetField } from '../ProductionDateOffsetField';
import { CheckboxField } from './CheckboxField';
import { CommonTextField } from './CommonTextField';
import { SelectLocation } from './SelectLocation';
import { TimePickerField } from './TimePickerField';
import clsx from 'clsx';
import { SelectFuel } from '../SelectFuel';
import { calculateTourTransportationDates } from '../../lib/tour_leg_dates';

export interface LegFieldInput {
  key: string;
  isAnyLinkedOrder?: boolean;
  id?: number;
  fuel: FuelType;
  load: boolean;
  unload: boolean;
  note?: string;
  arrivalTime?: Maybe<string>;
  departureTime?: Maybe<string>;
  actualArrivalDate?: string;
  actualDepartureDate?: string;
  position: number;
  productionDate?: string;
  transportationDate?: string;
  transportationDateOffset: number;
  daysDiff?: number;
  locationId?: number;
  isNonArrivalMarked?: boolean;
  nonArrivalMarkedByUserName?: string;
  nonArrivalMarkedFrom?: string;
  nonArrivalMarkedAt?: string;
  loadingListItems: LoadingListItemInterface[];
  location?: { deletedBy?: string };
  gateNumber?: string;
  carRegistrationNumber?: string;
  trailerRegistrationNumber?: string;
  routeDriverName?: string;
  routeDriverPhoneNumber?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(0.5, 0),
  },
  legField: {
    width: '100%',
    fontSize: '10px',
    marginRight: 20,
    marginTop: 18,
    marginBottom: 18,
  },
  field: {
    width: '100%',
    fontSize: '10px',
    margin: 0,
  },
  checkboxLabel: {
    fontSize: theme.typography.fontSize,
  },
  smallCheckboxLabel: {
    color: '#fc3926',
    fontSize: 12,
  },
  fieldsRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: -8,

    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: 500,
      marginTop: 8,
    },
    '& > *:first-child': {
      width: '100%',
    },
    '& > *:last-child': {
      minWidth: 625,
      flex: 2,
      justifyContent: 'space-between',
    },
  },
  fieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  transportationWrapper: {
    margin: '0 8px 0 25px',

    '& input': {
      padding: '8px 0',
      width: 101,
      textAlign: 'center',
    },
  },
  noteCheckbox: {
    margin: '0 8px',
  },
  loadingListButton: {
    padding: 0,
  },
  pushRight: {
    marginLeft: 14,
  },
  buttonWrapper: {
    display: 'flex',

    '& > *': {
      width: '24px',
      height: '24px',
      padding: '2px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  divider: {
    height: '5px',
    margin: '2px 0',
    backgroundColor: theme.palette.primary.light,
  },
}));

interface LegFieldsProps {
  leg: LegFieldInput;
  readOnly: boolean;
  legsPath: string;
  trafficReadOnly: boolean;
  isFromTerminal: boolean;
  index: number;
  legsLength: number;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  onRemove: (index: number) => void;
  from: string;
  fromCreateRoute?: string;
  isCreateTerminalRoute?: boolean;
  enableEditMode?: boolean;
  isTruckLoading?: boolean;
}

export function LegFields(props: LegFieldsProps) {
  const { t } = useTranslation();
  const {
    leg,
    legsPath,
    readOnly,
    trafficReadOnly, //terminalORreadonly
    index,
    legsLength,
    onMoveUp,
    onMoveDown,
    onRemove,
    from,
    fromCreateRoute = '',
    isCreateTerminalRoute = false,
    isFromTerminal = false,
    enableEditMode = false,
    isTruckLoading = false,
  } = props;
  const deletedBy = leg.location ? leg.location.deletedBy : '';
  const classes = useStyles();

  const { setFieldValue, getFieldProps, getFieldMeta, setFieldTouched } =
    useFormikContext<{ transportationDate: string; legs: LegFieldInput[] }>();
  const legs = getFieldProps(`${legsPath}`).value;
  const legsWithDays = calculateTourTransportationDates(legs);

  const [loadingListOpen, setLoadingListOpen] = useState<boolean>(
    leg.loadingListItems.length >= 0,
  );
  const legPath = `${legsPath}.${index}`;
  const isLegLoad = getFieldProps(`${legPath}.load`).value;
  const isLegUnload = getFieldProps(`${legPath}.unload`).value;
  useEffect(() => {
    if (!readOnly) {
      setFieldTouched(`${legPath}.load`, true);
      setFieldTouched(`${legPath}.unload`, true);
    }
  }, [readOnly]);
  // //for one selection

  useEffect(() => {
    if (isLegLoad && getFieldMeta(`${legPath}.load`).touched) {
      setFieldValue(`${legPath}.unload`, false);
    }
  }, [isLegLoad]);

  useEffect(() => {
    if (isLegUnload && getFieldMeta(`${legPath}.unload`).touched) {
      setFieldValue(`${legPath}.load`, false);
    }
  }, [isLegUnload]);
  const isMarkedAsNonArrival = getFieldProps(
    `${legPath}.isNonArrivalMarked`,
  ).value;
  const nonArrivalMarkedFrom = getFieldProps(
    `${legPath}.nonArrivalMarkedFrom`,
  ).value;
  const handleClearNote = useCallback(() => {
    setFieldValue(`${legPath}.note`, undefined);
  }, []);

  return (
    <Box className={classes.root}>
      <Box display="flex">
        <ViewDateField
          label={`${t('attributes.transportationDate')}:`}
          value={leg.transportationDate}
        />
        {leg.daysDiff != undefined && readOnly && (
          <Typography
            style={{
              color: '#1c1c1c',
              backgroundColor: '#e2e2e2',
              fontSize: 13,
              paddingLeft: 4,
              paddingRight: 4,
              fontWeight: 'bold',
              marginBottom: 10,
            }}
          >{`Transport Day: ${leg.daysDiff}`}</Typography>
        )}
      </Box>
      <Box className={classes.fieldsRoot}>
        <Grid container justifyContent="flex-start">
          <Grid item xs={6}>
            <Box display="flex" alignItems="flex-start">
              <SelectLocation
                name={`${legPath}.locationId`}
                className={classes.field}
                readOnly={
                  trafficReadOnly ||
                  (isCreateTerminalRoute && isFromTerminal
                    ? isTruckLoading
                      ? legPath === 'legs.0'
                      : legPath === 'legs.1'
                    : false)
                }
                size="small"
                smallFontSize={true}
                fullWidth
                withLink
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            {fromCreateRoute === 'ViewRoute' && leg.id != undefined && (
              <Box
                display="flex"
                alignItems="flex-start"
                sx={{
                  marginLeft: 4,
                }}
              >
                {!readOnly && !isFromTerminal ? (
                  <FormControlLabel
                    className={classes.noteCheckbox}
                    value={isMarkedAsNonArrival}
                    onChange={(e, c) => {
                      setFieldValue(`${legPath}.isNonArrivalMarked`, c);
                    }}
                    control={
                      <Checkbox
                        style={{ padding: 0, color: '#fc3926' }}
                        checked={isMarkedAsNonArrival}
                        size="small"
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        className={classes.smallCheckboxLabel}
                      >
                        {'Non-arrival'}
                      </Typography>
                    }
                  />
                ) : isMarkedAsNonArrival ? (
                  <Typography
                    variant="body2"
                    className={classes.smallCheckboxLabel}
                  >
                    {`Non-arrival ${
                      nonArrivalMarkedFrom
                        ? `[Marked from ${nonArrivalMarkedFrom}]`
                        : ''
                    }`}
                  </Typography>
                ) : null}
              </Box>
            )}
            <Box
              sx={{
                marginLeft: 10,
              }}
            >
              <SelectFuel
                fieldName={`${legPath}.fuel`}
                readOnly={trafficReadOnly}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item container>
          {deletedBy ? (
            <Typography variant="body2" className={classes.smallCheckboxLabel}>
              {t('validation.deletedLocation')}
            </Typography>
          ) : null}
        </Grid>

        <Box display="flex" alignItems="centre" justifyContent="space-between">
          <CommonTextField
            name={`${legPath}.gateNumber`}
            className={classes.legField}
            readOnly={trafficReadOnly}
            style={{ maxWidth: 130 }}
          />

          <CommonTextField
            name={`${legPath}.carRegistrationNumber`}
            type="string"
            className={classes.legField}
            readOnly={trafficReadOnly}
          />

          <CommonTextField
            name={`${legPath}.trailerRegistrationNumber`}
            type="string"
            className={classes.legField}
            readOnly={trafficReadOnly}
          />

          <CommonTextField
            name={`${legPath}.routeDriverName`}
            type="string"
            className={classes.legField}
            readOnly={trafficReadOnly}
          />

          <CommonTextField
            name={`${legPath}.routeDriverPhoneNumber`}
            type="string"
            className={classes.legField}
            readOnly={trafficReadOnly}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" flexWrap>
          <Box display="flex" flexWrap alignItems="center">
            <Box className={classes.fieldsWrapper}>
              <CheckboxField
                name={`${legPath}.load`}
                label={t('attributes.load')}
                readOnly={trafficReadOnly || isCreateTerminalRoute}
                size="small"
              />
              <CheckboxField
                name={`${legPath}.unload`}
                label={t('attributes.unload')}
                readOnly={trafficReadOnly || isCreateTerminalRoute}
                size="small"
              />
            </Box>
            <TimePickerField
              name={`${legPath}.arrivalTime`}
              readOnly={trafficReadOnly}
            />
            {!isCreateTerminalRoute && (
              <TimePickerField
                name={`${legPath}.departureTime`}
                readOnly={trafficReadOnly}
              />
            )}

            {index !== 0 && !isCreateTerminalRoute && (
              <Box className={classes.transportationWrapper}>
                <TransportationDateOffsetField
                  name={`${legPath}.transportationDateOffset`}
                  readOnly={trafficReadOnly}
                />
              </Box>
            )}
            {!trafficReadOnly && (
              <Box ml={2}>
                <FormControlLabel
                  className={classes.noteCheckbox}
                  value={leg.note != null}
                  disabled={readOnly}
                  onChange={() => {
                    if (leg.note == null) {
                      setFieldValue(`${legPath}.note`, '');
                    }
                    if (leg.note === '') {
                      setFieldValue(`${legPath}.note`, undefined);
                    }
                  }}
                  control={
                    <Checkbox
                      style={{ padding: 0 }}
                      checked={leg.note != null}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      className={classes.checkboxLabel}
                      color="textPrimary"
                    >
                      {'Add note'}
                    </Typography>
                  }
                />
              </Box>
            )}
            {leg.load && !isCreateTerminalRoute && (
              <Tooltip
                title={`${t('button.view', {
                  item: t('resource.loadingList.lowercased'),
                })}`}
              >
                <IconButton
                  className={clsx(classes.loadingListButton, {
                    [classes.pushRight]: index === 0,
                  })}
                  color={loadingListOpen ? 'primary' : undefined}
                  disabled={readOnly && leg.loadingListItems.length === 0}
                  onClick={() => {
                    setLoadingListOpen(!loadingListOpen);
                  }}
                >
                  <ListAltIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {!readOnly && from === 'TourRouteLeg' && (
            <Typography
              style={{
                color: '#1c1c1c',
                backgroundColor: '#e2e2e2',
                fontSize: 13,
                paddingLeft: 4,
                paddingRight: 4,
                fontWeight: 'bold',
                marginBottom: 5,
              }}
            >{`Transport Day: ${legsWithDays[index].daysDiff}`}</Typography>
          )}
          {!trafficReadOnly &&
            !isCreateTerminalRoute &&
            !isFromTerminal &&
            legsLength > 1 && (
              <Box className={classes.buttonWrapper}>
                <IconButton
                  disabled={index === 0}
                  onClick={() => {
                    onMoveUp(index);
                  }}
                  size="small"
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  disabled={index === legs.length - 1}
                  onClick={() => {
                    onMoveDown(index);
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    onRemove(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
        </Box>
      </Box>
      {leg.note != null && (
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          marginTop={1}
        >
          <CommonTextField
            name={`${legPath}.note`}
            readOnly={trafficReadOnly}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: !readOnly && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear note"
                    onClick={() => handleClearNote()}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      <Box
        width="100%"
        sx={{ bgcolor: enableEditMode ? '#fff3e0' : '' }}
        display={!loadingListOpen ? 'none' : 'block'}
      >
        {leg.load && (
          <LoadingList
            load={leg.load}
            formikPathName={`${legPath}.loadingListItems`}
            readOnly={readOnly}
            orders={leg.loadingListItems}
            isCreateTerminalRoute={isCreateTerminalRoute}
            isFromCreate={
              isCreateTerminalRoute ||
              fromCreateRoute === 'CreateSporadicRoute' ||
              fromCreateRoute === 'TourRouteLeg'
            }
          />
        )}
      </Box>

      {leg.load &&
        leg.loadingListItems &&
        leg.loadingListItems.length > 0 &&
        from === 'LegsFields' &&
        leg.loadingListItems.findIndex((ll) => ll.isDangerous === true) !==
          -1 && (
          <Typography
            style={{
              marginTop: 6,
              marginBottom: 2,
              fontSize: 12,
              fontWeight: 'bolder',
              color: 'red',
            }}
          >
            {`${t('attributes.warningOfDangerousGoods')}`}
          </Typography>
        )}
      {legsLength - 1 !== index && <Divider className={classes.divider} />}
    </Box>
  );
}
const useStyleViewDate = makeStyles(() => ({
  root: {
    display: 'flex',

    '& p': {
      fontSize: 12,
      marginRight: 5,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface ViewDateFieldProps {
  label: string;
  value?: string;
}

function ViewDateField(props: ViewDateFieldProps) {
  const { label, value } = props;
  const classes = useStyleViewDate();

  if (value == null || value === '') {
    return null;
  }

  const date = parse(value, DATE_FORMAT, new Date());
  const dateString = format(date, DATE_FORMAT);

  return (
    <Box className={classes.root}>
      <Typography className={classes.bold}>{label}</Typography>
      <Typography>{dateString}</Typography>
    </Box>
  );
}
